/**
 * @Copyright 2021 @DigiNet
 * @Author XUANLOC
 * @Create 27/3/2021
 * @Example
 */

import { LoadPanel } from 'devextreme-react';
import { Avatar, CircularProgress, TextInput, Typography } from 'diginet-core-ui/components';
import { ArrowDown } from 'diginet-core-ui/icons';
import html2canvas from 'html2canvas';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import InlineSVG from 'react-inlinesvg';
import { useDispatch, useSelector } from 'react-redux';
import Config from '../../../../../config';
import * as generalActions from '../../../../../redux/general/general_actions';
import Api from '../../../../../services/api';

const W39F3000RightCol = forwardRef((props, ref) => {
    const { tabIndex, classes, current, changeCurrent, selectedGoalPlan, canvasHtml } = props;

    const [data, setData] = useState({ rows: [], total: 0 });
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();
    let dataGoalPlan = useSelector((state) => state.W39F3000.detailData);
    if (selectedGoalPlan !== dataGoalPlan?.GoalPlanID) {
        dataGoalPlan = {};
    }

    const orgChartData = useRef(null);
    const typingTimeoutRef = useRef(null);
    const planProgressRef = useRef(null);

    const menuType = _.toNumber(Config.getLocalStorage("MENUTYPEDHR"));

    const paramEmployee = useRef({
        skip: 0,
        limit: 10,
        search: '',
    });

    const htmlToCanvas = async () => {
        if (planProgressRef.current) {
            const svgElements = planProgressRef.current.querySelectorAll('svg');
            svgElements.forEach(function (item) {
                item.setAttribute('width', item.getBoundingClientRect().width);
                item.setAttribute('height', item.getBoundingClientRect().height);
                item.style.width = null;
                item.style.height = null;
            });

            await html2canvas(planProgressRef.current, {
                scale: 2,
                allowTaint: true,
                useCORS: true,
                scrollX: 0,
                scrollY: -window.scrollY,
            }).then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                if (canvas && imgData) {
                    try {
                        canvasHtml.current.planProgressImg = imgData;
                    } catch (err) {
                        Config.popup.show('ERROR', err);
                    }
                } else {
                    Config.popup.show('ERROR', Config.lang('Co_loi_xay_ra_trong_qua_trinh_xu_ly'));
                }
            });
        }
    };

    useImperativeHandle(ref, () => ({
        htmlToCanvas: htmlToCanvas,
    }));

    const loadData = async (isReset) => {
        let param;
        setLoading(true);
        if (tabIndex === 0) {
            param = {
                Type: 'EmployeeID',
                FormID: 'W39F3000',
                Language: Config.language || '84',
                ...paramEmployee.current,
            };
            dispatch(
                generalActions.getCboEmployees(param, (err, dt) => {
                    setLoading(false);
                    if (err) return Config.popup.show('ERROR', err);
                    if (dt) {
                        const rows = _.get(dt, 'rows', false) || dt;
                        const total = _.get(dt, 'total', 0) || _.size(dt);
                        setData({ rows: isReset ? rows : _.concat(data.rows, rows), total });
                    }
                })
            );
        } else {
            param = {
                FormID: 'W39F3000',
                HostID: '',
                OrgChartID: '',
                Mode: 0,
                Key01ID: '',
            };
            const res = await Api.put('/w09f2005/load-data-org-chart', param);
            const resData = _.get(res, 'data', null);
            const resDataStatus = _.get(resData, 'Status', 0);
            setLoading(false);
            if (res && (!resData || resDataStatus === 1)) {
                const errMsg = _.get(res, 'message', _.get(resData, 'Message', Config.lang('Co_loi_xay_ra_trong_qua_trinh_xu_ly')));
                return Config.popup.show('ERROR', errMsg);
            }
            orgChartData.current = resData;
            setData(resData);
        }
    };

    useEffect(() => {
        loadData(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [current, tabIndex]);

    const onChange = (value) => {
        if (typingTimeoutRef.current) {
            clearInterval(typingTimeoutRef.current);
        }
        if (tabIndex === 0) {
            typingTimeoutRef.current = setTimeout(() => {
                paramEmployee.current.skip = 0;
                paramEmployee.current.search = value;
                loadData(true);
            }, 500);
        } else {
            typingTimeoutRef.current = setTimeout(() => {
                const newData = orgChartData.current.filter((i) => i.OrgChartName.toLowerCase().includes(value.toLowerCase()));
                setData(newData);
            }, 500);
        }
    };

    const handleScroll = (e) => {
        const el = e.target;
        if (el.scrollTop === el.scrollHeight - el.clientHeight) {
            if (data.total) {
                if (paramEmployee.current.skip + paramEmployee.current.limit < data.total) {
                    paramEmployee.current.skip = paramEmployee.current.skip + paramEmployee.current.limit;
                    loadData();
                }
            }
        }
        el.removeEventListener('scroll', handleScroll);
    };

    const getOrgShortName = (name) => {
        name = name.trim();
        let shortName = '';
        const hyphenIdx = name.indexOf('-');
        if (hyphenIdx > -1) {
            name = name.substring(hyphenIdx + 1).trim();
        }
        if (name && name.length > 0) {
            const splitName = name.split(' ');
            if (splitName.length > 1) {
                shortName = `${splitName[splitName.length - 2][0]}${splitName[splitName.length - 1][0]}`;
            } else {
                shortName = splitName[splitName.length - 1][0];
            }
            shortName = shortName.toUpperCase();
        }
        return shortName;
    };

    const getOrgName = (name) => {
        name = name.trim();
        let newName = name;
        const hyphenIdx = name.indexOf('-');
        if (hyphenIdx > -1) {
            newName = name.substring(hyphenIdx + 1).trim();
        }
        return newName;
    };

    const renderCurrent = () => {
        let crt;
        if (tabIndex === 0) {
            crt = (
                <div className={classes.divCurrent}>
                    <Avatar
                        clearAble={false}
                        width={64}
                        height={64}
                        readOnly
                        outlined={'1px solid white'}
                        style={{ boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)' }}
                        src={current?.UserPictureURL ?? ''}
                    />
                    <div>
                        <Typography type={'h3'} style={{ marginBottom: 5 }}>
                            {current?.EmployeeName ?? ''}
                        </Typography>
                        <Typography type={'h3'} color="secondary" style={{ fontSize: 12 }}>
                            {current?.DutyName ?? ''}
                        </Typography>
                    </div>
                </div>
            );
        } else {
            crt = (
                <div className={classes.divCurrent}>
                    <span className={classes.spanOrg} style={{ width: 64, height: 64 }}>
                        {getOrgShortName(current?.OrgChartName ?? '')}
                    </span>
                    <div>
                        <Typography type={'h3'} style={{ marginBottom: 5 }}>
                            {getOrgName(current?.OrgChartName ?? '')}
                        </Typography>
                    </div>
                </div>
            );
        }
        return crt;
    };

    const renderListOther = () => {
        let listOther;
        if (tabIndex === 0) {
            listOther = (
                <>
                    <div id="divListOther" className={`${classes.divListOther} ${classes.flexGap10}`} onScroll={handleScroll}>
                        {data.rows &&
                            data.rows.length > 0 &&
                            data.rows.map((emp, idx) => {
                                return emp ? (
                                    <div key={idx} className={classes.other} onClick={() => changeCurrent(emp)}>
                                        <Avatar
                                            clearAble={false}
                                            width={32}
                                            height={32}
                                            readOnly
                                            outlined={'1px solid white'}
                                            style={{ boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)' }}
                                            src={
                                                emp?.UserPictureURL?.indexOf('http') < 0
                                                    ? Config.getCDNPath() + emp?.UserPictureURL
                                                    : emp?.UserPictureURL
                                            }
                                        />
                                        <div>
                                            <Typography type={'h3'} style={{ fontSize: 14 }}>{`${emp?.EmployeeID}-${emp?.EmployeeName}`}</Typography>
                                            <Typography type={'h3'} color="secondary" style={{ fontSize: 12 }}>
                                                {emp?.DutyName || ''}
                                            </Typography>
                                        </div>
                                    </div>
                                ) : (
                                    <div key={idx}/>
                                );
                            })}
                        {data.rows && data.rows.length === 0 && (
                            <Typography type={'h3'} color="secondary" style={{ margin: 'auto' }}>
                                {Config.lang('Khong_co_du_lieu')}
                            </Typography>
                        )}
                    </div>
                    {data.rows && data.rows.length < data.total && (
                        <div className={classes.divArrowDown}>
                            <ArrowDown />
                        </div>
                    )}
                </>
            );
        } else {
            listOther = (
                <>
                    <div id="divListOther" className={`${classes.divListOther} ${classes.flexGap10}`}>
                        {data.length > 0 &&
                            data.map((org, idx) => {
                                return (
                                    <div key={idx} className={classes.other} onClick={() => changeCurrent(org)}>
                                        <span className={classes.spanOrg}>{getOrgShortName(org.OrgChartName || '')}</span>
                                        <div>
                                            <Typography type={'h3'} style={{ fontSize: 14 }}>{`${org?.OrgChartID}-${getOrgName(
                                                org?.OrgChartName ?? ''
                                            )}`}</Typography>
                                        </div>
                                    </div>
                                );
                            })}
                        {data.length === 0 && (
                            <Typography type={'h3'} color="secondary" style={{ margin: 'auto' }}>
                                {Config.lang('Khong_co_du_lieu')}
                            </Typography>
                        )}
                    </div>
                </>
            );
        }
        return listOther;
    };

    return (
        <>
            <LoadPanel
                shadingColor="rgba(0,0,0,0.4)"
                position={{ my: 'center', of: '#divListOther' }}
                visible={loading}
                showIndicator={true}
                shading={true}
                showPane={true}
            />
            <div className={classes.divPlanProgress} ref={planProgressRef}>
                <div className={classes.divChildPlanProgress}>
                    <CircularProgress
                        color="#00C875"
                        determinate
                        direction="bottom"
                        percent={dataGoalPlan.PlanResult || 0}
                        percentColor="#D4D5D9"
                        size="md"
                        strokeWidth={8}
                    />
                    <Typography type={'p2'} style={{ marginTop: 10 }} center>
                        {Config.lang('Tien_do_thuc_hien')}
                    </Typography>
                </div>
                {dataGoalPlan.PeriodResult > 0 && (
                    <div className={classes.divChildPlanProgress}>
                        <CircularProgress
                            color="#FFAA00"
                            determinate
                            direction="bottom"
                            percent={dataGoalPlan.PeriodResult || 0}
                            percentColor="#D4D5D9"
                            size="md"
                            strokeWidth={8}
                        />
                        <Typography type={'p2'} style={{ marginTop: 10 }} center>
                            {dataGoalPlan.PeriodName || ''}
                        </Typography>
                    </div>
                )}
            </div>
            <Typography type={'h3'} style={{ marginTop: 20, fontSize: 14 }}>
                <InlineSVG src={require('../icons/big_dot.svg')} width={6} height={6} style={{ marginRight: 5 }} />
                {Config.lang('Ban_dang_xem_muc_tieu_cua')}
            </Typography>
            {renderCurrent()}
            {!!menuType ?
                <>
                    <Typography type={'h3'} style={{marginTop: 20, fontSize: 14}}>
                        <InlineSVG src={require('../icons/big_dot.svg')} width={6} height={6} style={{marginRight: 5}}/>
                        {tabIndex === 0 ? Config.lang('Xem_muc_tieu_cua_nguoi_khac') : Config.lang('Xem_muc_tieu_cua_co_cau_to_chuc_khac')}
                    </Typography>
                    <div className={classes.divOther}>
                        <TextInput
                            icon="Search"
                            placeholder={tabIndex === 0 ? Config.lang('Tim_kiem_nhan_vien') : Config.lang('Tim_kiem_co_cau_to_chuc')}
                            viewType="outlined"
                            style={{width: '100%'}}
                            onChange={(e) => onChange(e.target.value)}
                        />
                        {renderListOther()}
                    </div>
                </> : ""}
        </>
    );
});

W39F3000RightCol.propTypes = {
    tabIndex: PropTypes.number,
    classes: PropTypes.object,
    current: PropTypes.object,
    changeCurrent: PropTypes.func,
    selectedGoalPlan: PropTypes.string,
    canvasHtml: PropTypes.object,
};

W39F3000RightCol.defaultProps = {
    tabIndex: 0,
    classes: {},
    current: {},
    changeCurrent: null,
    selectedGoalPlan: '',
    canvasHtml: { current: {} },
};

export default W39F3000RightCol;
