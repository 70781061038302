/**
 * @Copyright 2021 @DigiNet
 * @Author XUANLOC
 * @Create 27/3/2021
 * @Example
 */

import {Tab, Tabs} from '@material-ui/core';
import {LoadPanel} from 'devextreme-react';
import {Button, ButtonIcon, Popover as CorePopover, Typography} from 'diginet-core-ui/components';
import Icon from 'diginet-core-ui/icons';
import _ from 'lodash';
import moment from 'moment';
import React, {useEffect, useRef, useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import InlineSVG from 'react-inlinesvg';
import {useDispatch, useSelector} from 'react-redux';
import {browserHistory} from 'react-router';
import Popover from '../../../../components/grid-container/popover-action';
import Config from '../../../../config';
import * as generalActions from '../../../../redux/general/general_actions';
import * as W39F3000Actions from '../../../../redux/W3X/W39F3000/W39F3000_actions';
import ButtonGeneral from '../../../common/button/button-general';
import DropDownOrganization from '../../../common/dropdown/dropdown-treeview';
import {Combo} from '../../../common/form-material';
import DateBoxPicker from '../../../common/form-material/date-box';
import Modal from '../../../common/modal/modal';
import ActionToolbar from '../../../common/toolbar/action-toolbar';
import Filter from '../../../filter/filter';
import W39F3001 from '../W39F3001/W39F3001';
import W39F3002 from '../W39F3002/W39F3002';
import W39F3004 from '../W39F3004/W39F3004';
import W39F3005 from '../W39F3005/W39F3005';
import {useStyles} from './styles';
import W39F3000LeftCol from './utils/W39F3000LeftCol';
import W39F3000RightCol from './utils/W39F3000RightCol';

function W39F3000(props) {
    const { location } = props;

    const [iPermission, setIPermission] = useState(0);
    const [loading, setLoading] = useState(false);
    const [dataCboStatusGoal, setDataCboStatusGoal] = useState([]);
    const [dataCboGoalTable, setDataCboGoalTable] = useState([]);
    const [dataCboEmployees, setDataCboEmployees] = useState({ rows: [], total: 0 });
    const [currentEmployee, setCurrentEmployee] = useState(() => {
        return Config.getUser({ EmployeeID: Config.profile.HREmployeeID || '' });
    });
    const [isRenderModalW39F3001, setIsRenderModalW39F3001] = useState(false);
    const [currentOrgChart, setCurrentOrgChart] = useState(() => {
        return {
            OrgChartID: currentEmployee?.OrgChartID ?? '',
            OrgChartName: currentEmployee?.OrgChartName ?? '',
        };
    });
    const [isLoadingCboEmployee, setLoadingCboEmployee] = useState(false);
    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);
    const [tempDateFrom, setTempDateFrom] = useState(null);
    const [tempDateTo, setTempDateTo] = useState(null);
    const [isSetDate, setIsSetDate] = useState(false);
    const [selectedDateRange, setSelectedDateRange] = useState('');
    const [tabIndex, setTabIndex] = useState(0);
    const [toogleColorDes, setToogleColorDes] = useState(false);
    const [toogleActBtnCont, setToogleActBtnCont] = useState(false);
    const [isOpenW39F3005, setIsOpenW39F3005] = useState(false);
    const [isOpenPopW39F3002, setIsOpenPopW39F3002] = useState(false);
    const [dataW39F3002, setDataW39F3002] = useState({ GoalID: '', Type: '' });
    const [isOpenW39F3004, setIsOpenW39F3004] = useState(false);
    const [goalData, setGoalData] = useState({});
    const [dataCboStatusGoalPlan, setDataCboStatusGoalPlan] = useState([]);
    const [yearFrom, setYearFrom] = useState(null);
    const [yearTo, setYearTo] = useState(null);
    const [selectedGoalPlan, setSelectedGoalPlan] = useState('');
    const [isShowBtnAct, setIsShowBtnAct] = useState(false);

    const dispatch = useDispatch();
    const dataTab1 = useSelector((state) => state.W39F3000.data);

    const classes = useStyles();

    const dateFilter = useRef();
    const filter = useRef({
        GoalPlanID: '',
        EmployeeID: currentEmployee?.EmployeeID ?? '',
        OrgChartID: currentOrgChart?.OrgChartID ?? '',
        StatusID: null,
        DateFrom: null,
        DateTo: null,
        GoalPlanStatusID: null,
        skip: 0,
        limit: 20,
    });
    const btnColorDes = useRef(null);
    const btnColorDes2 = useRef(null);
    const btnActCont = useRef(null);
    const loginEmployee = useRef(Config.getUser({ EmployeeID: Config.profile.HREmployeeID || '' }));
    const tempOrgChart = useRef(null);
    const canvasHtml = useRef({});
    const leftCol = useRef({});
    const rightCol = useRef({});

    const menuType = _.toNumber(Config.getLocalStorage("MENUTYPEDHR"));

    const paramFilterCboEmployee = {
        skip: 0,
        limit: 10,
        search: '',
    };

    const loadPermission = () => {
        dispatch(
            generalActions.getPermission(
                'W39F3003',
                (isPer) => {
                    setIPermission(isPer);
                },
                true
            )
        );
    };

    const loadPermissionIsOKRs = () => {
        dispatch(generalActions.getPermission('W39F3000', null));
    };

    const loadData = (mode = 1) => {
        const param = {
            GoalPlanID: mode ? filter.current?.GoalPlanID ?? '' : selectedGoalPlan ?? '',
            StatusID: filter.current?.StatusID ?? null,
            DivisionID: Config.getDivisionID() || '',
            FormID: 'W39F3000',
            Language: Config.language || '84',
            UserID: Config.profile.UserID || '',
            GoalMode: tabIndex,
            TransID: location?.state?.TransID ?? '',
            GoalPlanStatusID: filter.current?.GoalPlanStatusID ?? null,
            Mode: mode,
        };
        if (filter.current.DateFrom) {
            param.DateFrom = filter.current.DateFrom;
        }
        if (filter.current.DateTo) {
            param.DateTo = filter.current.DateTo;
        }
        if (tabIndex === 0) {
            param.EmployeeID = currentEmployee?.EmployeeID ?? '';
        }
        if (tabIndex === 1) {
            param.OrgChartID = currentOrgChart?.OrgChartID ?? '';
        }
        if (yearFrom) {
            param.YearFrom = parseInt(yearFrom?.split('-')?.[0] ?? 0);
        }
        if (yearTo) {
            param.YearTo = parseInt(yearTo?.split('-')?.[0] ?? 0);
        }
        if (mode) {
            param.skip = filter.current?.skip;
            param.limit = filter.current?.limit;
        }
        setLoading(true);
        dispatch(
            W39F3000Actions[mode ? 'getData' : 'getDetailData'](param, (err, data) => {
                setLoading(false);
                if (err) return Config.popup.show('ERROR', err);
                if (mode && data?.rows?.[0]) {
                    const { GoalPlanID } = data?.rows?.[0];
                    const SelectedGoalPlan = location?.state?.SelectedGoalPlan;
                    if (!(selectedGoalPlan || SelectedGoalPlan)) {
                        setSelectedGoalPlan(GoalPlanID ?? '');
                    } else if (!selectedGoalPlan && SelectedGoalPlan) {
                        const newState = {...(location?.state ?? {})};
                        delete newState.SelectedGoalPlan;
                        browserHistory.replace({ ...location, state: newState });
                        setSelectedGoalPlan(SelectedGoalPlan);
                    }
                }
            })
        );
    };

    useEffect(() => {
        loadPermission();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(location.action === "PUSH") {
            loadPermissionIsOKRs()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[location.action])

    useEffect(() => {
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tabIndex, currentEmployee, currentOrgChart]);

    useEffect(() => {
        if (selectedGoalPlan) {
            loadData(0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedGoalPlan, dataTab1]);

    useEffect(() => {
        onWindowResize();
        window.addEventListener('resize', onWindowResize);
        return () => {
            window.removeEventListener('resize', onWindowResize);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onWindowResize = () => {
        setIsShowBtnAct(window.innerWidth < 641);
    };

    const loadCboStatusGoal = () => {
        const param = {
            Language: Config.language || '84',
            FormID: 'W39F3002',
        };
        dispatch(
            W39F3000Actions.getComboStatusGoal(param, (err, data) => {
                if (err) return Config.popup.show('ERROR', err);
                if (data) {
                    setDataCboStatusGoal(data);
                }
            })
        );
    };

    const loadCboEmployees = (isReset) => {
        const param = {
            Type: 'EmployeeID',
            FormID: 'W39F3000',
            Language: Config.language || '84',
            ...paramFilterCboEmployee,
        };
        setLoadingCboEmployee(true);
        dispatch(
            generalActions.getCboEmployees(param, (err, data) => {
                setLoadingCboEmployee(false);
                if (err) return Config.popup.show('ERROR', err);
                if (data) {
                    const rows = _.get(data, 'rows', false) || data;
                    const total = _.get(data, 'total', 0) || _.size(data);
                    setDataCboEmployees({ rows: isReset ? rows : _.concat(dataCboEmployees.rows, rows), total });
                }
            })
        );
    };

    const loadCboGoalTable = () => {
        dispatch(
            W39F3000Actions.getComboGoalTable((err, data) => {
                if (err) return Config.popup.show('ERROR', err);
                if (data) {
                    setDataCboGoalTable(data);
                }
            })
        );
    };

    const loadCboStatusGoalPlan = () => {
        const param = {
            Language: Config.language || '84',
            FormID: 'W39F3000',
        };
        dispatch(
            W39F3000Actions.getComboStatusGoalPlan(param, (err, data) => {
                if (err) return Config.popup.show('ERROR', err);
                if (data) {
                    setDataCboStatusGoalPlan(data);
                }
            })
        );
    };

    const onLoadCboFilter = () => {
        loadCboStatusGoal();
        loadCboEmployees();
        loadCboGoalTable();
        loadCboStatusGoalPlan();
    };

    const filterChange = (key, value) => {
        switch (key) {
            case 'GoalPlanID':
                filter.current[key] = value ?? '';
                break;
            case 'EmployeeID':
                filter.current[key] = value?.EmployeeID ?? '';
                break;
            case 'OrgChartID':
                filter.current[key] = value?.itemData?.OrgChartID ?? '';
                tempOrgChart.current = value?.itemData
                    ? { ...value?.itemData, OrgChartName: value?.itemData?.OrgName ?? value?.itemData?.OrgChartName }
                    : null;
                break;
            case 'TempDateFrom':
                setTempDateFrom(value);
                setSelectedDateRange('');
                break;
            case 'TempDateTo':
                setTempDateTo(value);
                setSelectedDateRange('');
                break;
            default:
                filter.current[key] = value;
                break;
        }
    };

    const openDatePicker = (e) => {
        const target = e.currentTarget;
        if (dateFilter) {
            dateFilter.current.instance.show(target);
        }
    };

    const closeDatePicker = () => {
        if (dateFilter) {
            dateFilter.current.instance.hide();
        }
    };

    const setDatePicker = () => {
        setIsSetDate(true);
        setDateFrom(tempDateFrom);
        setDateTo(tempDateTo);
        filterChange('DateFrom', tempDateFrom);
        filterChange('DateTo', tempDateTo);
        setTimeout(() => {
            if (dateFilter) {
                dateFilter.current.instance.hide();
            }
        }, 10);
    };

    const dateRangesArr = [
        {
            label: Config.lang('Mot_tuan_truoc'),
            value: '1_week_before',
        },
        {
            label: Config.lang('Tuan_nay'),
            value: 'current_week',
        },
        {
            label: Config.lang('Tuan_truoc'),
            value: 'last_week',
        },
        {
            label: Config.lang('Mot_thang_truoc'),
            value: '1_month_before',
        },
        {
            label: Config.lang('Thang_nay'),
            value: 'current_month',
        },
        {
            label: Config.lang('Thang_truoc'),
            value: 'last_month',
        },
        {
            label: Config.lang('Quy_nay'),
            value: 'current_quarter',
        },
        {
            label: Config.lang('Quy_truoc'),
            value: 'last_quarter',
        },
        {
            label: Config.lang('Tat_ca'),
            value: 'all',
        },
    ];

    const onDatePickerHidding = () => {
        setSelectedDateRange('');
        if (isSetDate) {
            setTempDateFrom((dateFrom) => dateFrom);
            setTempDateTo((dateTo) => dateTo);
            setIsSetDate(false);
        } else {
            setTempDateFrom(dateFrom);
            setTempDateTo(dateTo);
        }
    };

    const searchGoal = () => {
        if (tabIndex === 0 && filter.current?.EmployeeID !== currentEmployee?.EmployeeID) {
            const newEmployee = Config.getUser({ EmployeeID: filter.current.EmployeeID });
            setCurrentEmployee(newEmployee);
        } else if (
            tabIndex === 1 &&
            filter.current?.OrgChartID === tempOrgChart.current?.OrgChartID &&
            filter.current?.OrgChartID !== currentOrgChart?.OrgChartID
        ) {
            setCurrentOrgChart(tempOrgChart.current);
        }
        loadData();
    };

    const clearDatePicker = (e) => {
        if (dateFrom || dateTo) {
            e.stopPropagation();
            setDateFrom(null);
            setDateTo(null);
            setTempDateFrom(null);
            setTempDateTo(null);
            filterChange('DateFrom', null);
            filterChange('DateTo', null);
        }
    };

    const setDatePickerByRange = (value) => {
        setSelectedDateRange(value);
        switch (value) {
            case '1_week_before': {
                const newDate = moment().subtract(1, 'week').format('YYYY-MM-DD');
                setTempDateFrom(newDate);
                setTempDateTo(newDate);
                break;
            }
            case 'current_week': {
                const newDateFrom = moment().startOf('isoWeek').format('YYYY-MM-DD');
                const newDateTo = moment().endOf('isoWeek').format('YYYY-MM-DD');
                setTempDateFrom(newDateFrom);
                setTempDateTo(newDateTo);
                break;
            }
            case 'last_week': {
                const newDateFrom = moment().subtract(1, 'week').startOf('isoWeek').format('YYYY-MM-DD');
                const newDateTo = moment().subtract(1, 'week').endOf('isoWeek').format('YYYY-MM-DD');
                setTempDateFrom(newDateFrom);
                setTempDateTo(newDateTo);
                break;
            }
            case '1_month_before': {
                const newDate = moment().subtract(1, 'month').format('YYYY-MM-DD');
                setTempDateFrom(newDate);
                setTempDateTo(newDate);
                break;
            }
            case 'current_month': {
                const newDateFrom = moment().startOf('month').format('YYYY-MM-DD');
                const newDateTo = moment().endOf('month').format('YYYY-MM-DD');
                setTempDateFrom(newDateFrom);
                setTempDateTo(newDateTo);
                break;
            }
            case 'last_month': {
                const newDateFrom = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
                const newDateTo = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
                setTempDateFrom(newDateFrom);
                setTempDateTo(newDateTo);
                break;
            }
            case 'current_quarter': {
                const newDateFrom = moment().startOf('quarter').format('YYYY-MM-DD');
                const newDateTo = moment().endOf('quarter').format('YYYY-MM-DD');
                setTempDateFrom(newDateFrom);
                setTempDateTo(newDateTo);
                break;
            }
            case 'last_quarter': {
                const newDateFrom = moment().subtract(1, 'quarter').startOf('quarter').format('YYYY-MM-DD');
                const newDateTo = moment().subtract(1, 'quarter').endOf('quarter').format('YYYY-MM-DD');
                setTempDateFrom(newDateFrom);
                setTempDateTo(newDateTo);
                break;
            }
            case 'all': {
                setTempDateFrom(null);
                setTempDateTo(null);
                break;
            }
            default:
                break;
        }
    };

    const renderFilter = () => {
        return (
            <Filter
                placeholder={Config.lang('Noi_dung_can_tim_kiem')}
                openOnFieldClick={true}
                onOpenLoaded={onLoadCboFilter}
                renderFilter={() => (
                    <>
                        <div style={{ marginBottom: '7px' }}>
                            <Combo
                                label={Config.lang('Bang_muc_tieu')}
                                shrink={true}
                                dataSource={dataCboGoalTable}
                                displayExpr={'GoalPlanName'}
                                valueExpr={'GoalPlanID'}
                                onValueChanged={(e) => filterChange('GoalPlanID', e.value)}
                                showClearButton={true}
                                value={filter.current.GoalPlanID}
                                placeholder={Config.lang('Chon')}
                            />
                        </div>
                        <div style={{ marginBottom: '7px' }}>
                            {tabIndex === 0 ? (
                                <Combo.LoadMore
                                    disabled={!menuType}
                                    dataSource={dataCboEmployees}
                                    skip={paramFilterCboEmployee.skip}
                                    limit={paramFilterCboEmployee.limit}
                                    displayExpr={'EmployeeName'}
                                    keyExpr={'EmployeeID'}
                                    valueExpr={'EmployeeID'}
                                    value={currentEmployee?.EmployeeID ?? ''}
                                    loading={isLoadingCboEmployee}
                                    stylingMode={'outlined'}
                                    label={Config.lang('Nhan_vien')}
                                    shrink={true}
                                    placeholder={Config.lang('Chon')}
                                    itemRender={(e) => {
                                        const { data } = e;
                                        if (!data) return null;
                                        return data.EmployeeID + ' - ' + data.EmployeeName;
                                    }}
                                    showClearButton={true}
                                    onValueChanged={(e) => filterChange('EmployeeID', e.value)}
                                    onInputChanged={(e) => {
                                        paramFilterCboEmployee.search = e.target.value;
                                        paramFilterCboEmployee.skip = 0;
                                        loadCboEmployees(true);
                                    }}
                                    onLoadMore={(e) => {
                                        paramFilterCboEmployee.skip = e.skip;
                                        paramFilterCboEmployee.limit = e.limit;
                                        loadCboEmployees();
                                    }}
                                />
                            ) : (
                                <DropDownOrganization
                                    label={Config.lang('Co_cau_to_chuc')}
                                    showClearButton
                                    typeDefault={'organizational'}
                                    value={currentOrgChart?.OrgChartID ?? ''}
                                    onValueChanged={(e) => filterChange('OrgChartID', e)}
                                />
                            )}
                        </div>
                        <div style={{ marginBottom: '7px' }}>
                            <Combo
                                label={Config.lang('Trang_thai_bang_muc_tieu')}
                                shrink={true}
                                dataSource={dataCboStatusGoalPlan}
                                displayExpr={'StatusName'}
                                valueExpr={'StatusID'}
                                onValueChanged={(e) => filterChange('GoalPlanStatusID', e.value)}
                                showClearButton={true}
                                value={filter.current.GoalPlanStatusID}
                                placeholder={Config.lang('Chon')}
                            />
                        </div>
                        <Row style={{ marginBottom: '7px' }}>
                            <Col xs={12} sm={12} md={6} lg={6}>
                                <DateBoxPicker
                                    label={Config.lang('Tu')}
                                    shrink={true}
                                    dateBoxProps={{
                                        max: yearTo ?? null,
                                        'calendarOptions.maxZoomLevel': 'decade',
                                        'calendarOptions.minZoomLevel': 'decade',
                                    }}
                                    onValueChanged={(e) => setYearFrom(e.value)}
                                    showClearButton={true}
                                    placeholder={'yyyy'}
                                    value={yearFrom ?? ''}
                                    displayFormat={'yyyy'}
                                />
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={6}>
                                <DateBoxPicker
                                    label={Config.lang('Den')}
                                    shrink={true}
                                    dateBoxProps={{
                                        min: yearFrom ?? null,
                                        'calendarOptions.maxZoomLevel': 'decade',
                                        'calendarOptions.minZoomLevel': 'decade',
                                    }}
                                    onValueChanged={(e) => setYearTo(e.value)}
                                    showClearButton={true}
                                    placeholder={'yyyy'}
                                    value={yearTo ?? ''}
                                    displayFormat={'yyyy'}
                                />
                            </Col>
                        </Row>
                        <div style={{ marginBottom: '7px' }}>
                            <Combo
                                label={Config.lang('Trang_thai_muc_tieu')}
                                shrink={true}
                                dataSource={dataCboStatusGoal}
                                displayExpr={'StatusName'}
                                valueExpr={'StatusID'}
                                onValueChanged={(e) => filterChange('StatusID', e.value)}
                                showClearButton={true}
                                value={filter.current.StatusID}
                                placeholder={Config.lang('Chon')}
                            />
                        </div>
                        <div style={{ marginBottom: '7px', display: 'flow-root' }}>
                            <div className={classes.dateFilter} onClick={openDatePicker}>
                                <label className={classes.labelDateFilter}>{Config.lang('Thoi_gian_muc_tieu')}</label>
                                <div className={classes.inputDateFilter} style={{ color: dateFrom || dateTo ? '#222B45' : '', fontWeight: 500 }}>
                                    {dateFrom || dateTo
                                        ? `${dateFrom ? moment(dateFrom).format('DD/MM/YYYY') : ''} - ${
                                              dateTo ? moment(dateTo).format('DD/MM/YYYY') : ''
                                          }`
                                        : 'dd/mm/yyyy - dd/mm/yyyy'}
                                </div>
                                <Icon
                                    className={classes.iconDateFilter}
                                    name={dateFrom || dateTo ? 'Cancel' : 'Calendar'}
                                    width={20}
                                    height={20}
                                    viewBox
                                    onClick={clearDatePicker}
                                />
                            </div>
                            <Popover reference={dateFilter} maxWidth={550} position={'bottom'} shading={false} onHiding={onDatePickerHidding}>
                                <div className={classes.datePicker}>
                                    <Row style={{ padding: 15 }}>
                                        <Col xs={12} sm={12} md={6} lg={6}>
                                            <DateBoxPicker
                                                label={Config.lang('Tu')}
                                                shrink={true}
                                                dateBoxProps={{ max: tempDateTo ?? null }}
                                                onValueChanged={(e) => filterChange('TempDateFrom', e.value)}
                                                showClearButton={true}
                                                placeholder={'dd/mm/yyyy'}
                                                value={tempDateFrom || ''}
                                            />
                                        </Col>
                                        <Col xs={12} sm={12} md={6} lg={6}>
                                            <DateBoxPicker
                                                label={Config.lang('Den')}
                                                shrink={true}
                                                dateBoxProps={{ min: tempDateFrom ?? null }}
                                                onValueChanged={(e) => filterChange('TempDateTo', e.value)}
                                                showClearButton={true}
                                                placeholder={'dd/mm/yyyy'}
                                                value={tempDateTo || ''}
                                            />
                                        </Col>
                                    </Row>
                                    <div className={classes.listDateRanges}>
                                        {dateRangesArr.map((i, index) => {
                                            return (
                                                <ButtonGeneral
                                                    key={index}
                                                    name={i.label}
                                                    size={'large'}
                                                    variant={'text'}
                                                    onClick={() => setDatePickerByRange(i.value)}
                                                    style={{ marginRight: 5, backgroundColor: selectedDateRange === i.value ? '#EFF5FF' : '' }}
                                                />
                                            );
                                        })}
                                    </div>
                                    <div className={classes.divButtonDate}>
                                        <Button
                                            text={Config.lang('Dong1')}
                                            viewType="outlined"
                                            onClick={closeDatePicker}
                                            style={{ marginRight: 5 }}
                                        />
                                        <Button text={Config.lang('Chon')} viewType="filled" color="info" onClick={setDatePicker} />
                                    </div>
                                </div>
                            </Popover>
                        </div>
                        <div style={{ display: "flex", justifyContent: "center", }}>
                            <Button
                                size={"medium"}
                                color={"primary"}
                                viewType={"outlined"}
                                label={Config.lang("Tim_kiem")}
                                startIcon={"Search"}
                                onClick={searchGoal}
                            />
                        </div>
                    </>
                )}
            />
        );
    };

    const redirect = (mode, data) => {
        let screen = '';
        let params = {};
        let isRedirect = true;
        switch (mode) {
            case 'detail':
                params.GoalID = data.GoalID;
                params.Type = data.Type;
                if (data.Type === 'Key') {
                    isRedirect = false;
                    setDataW39F3002(params);
                    handleOpenPopW39F3002(true);
                    break;
                } else {
                    params = {
                        ...params,
                        ...(location?.state ?? {}),
                        FromPath: 'W39F3000',
                        SelectedGoalPlan: selectedGoalPlan,
                    };
                }
                screen = 'W39F3002';
                break;
            case 'comment':
                screen = 'W39F3002';
                params = {
                    ...(location?.state ?? {}),
                    GoalID: data.GoalID,
                    Type: data.Type,
                    FromPath: 'W39F3000',
                    SelectedGoalPlan: selectedGoalPlan,
                };
                break;
            case 'edit':
                isRedirect = false;
                setIsOpenW39F3004(true);
                setGoalData(data);
                break;
            default:
                break;
        }
        if (isRedirect) {
            browserHistory.push({
                pathname: Config.getRootPath() + screen,
                state: params,
            });
        }
    };

    const changeTab = (e, value) => {
        setTabIndex(value);
        setSelectedGoalPlan('');
    };

    const changeCurrent = (data) => {
        if (data) {
            if (tabIndex === 0) {
                filter.current.EmployeeID = data.EmployeeID || '';
                setCurrentEmployee(data);
            } else {
                filter.current.OrgchartID = data.OrgChartID || '';
                setCurrentOrgChart(data);
            }
            loadData();
        }
    };

    const onToogleColorDes = () => {
        setToogleColorDes(!toogleColorDes);
    };

    const onToogleActBtnCont = () => {
        setToogleActBtnCont(!toogleActBtnCont);
    };

    const colorStatusArr = [
        {
            id: 0,
            label: Config.lang('Chua_hoan_thanh'),
            colorCode: Config.color ? Config.color['Success'] : '#00C875',
            colorStr: 'success',
        },
        {
            id: 1,
            label: Config.lang('Qua_han'),
            colorCode: Config.color ? Config.color['Danger'] : '#FF3D71',
            colorStr: 'danger',
        },
        {
            id: 2,
            label: Config.lang('Hoan_thanh'),
            colorCode: Config.color ? Config.color['Info'] : '#0095FF',
            colorStr: 'info',
        },
        {
            id: 3,
            label: Config.lang('Cham_tien_do'),
            colorCode: Config.color ? Config.color['Warning'] : '#FFAA00',
            colorStr: 'warning',
        },
    ];

    const changeLoading = (loading = false) => {
        setLoading(loading);
    };

    const handleOpenW39F3005 = (flag = false) => {
        if (flag && toogleActBtnCont) {
            setToogleActBtnCont(false);
        }
        setIsOpenW39F3005(flag);
    };

    const handleOpenPopW39F3002 = (flag = false) => {
        setIsOpenPopW39F3002(flag);
    };

    const handleOpenPop = (name, flag = false, data) => {
        switch (name) {
            case 'W39F3004':
                if (!flag && data) {
                    loadData();
                }
                setIsOpenW39F3004(flag);
                break;
            default:
                break;
        }
    };

    const onAdd = () => {
        setIsRenderModalW39F3001(true);
    };

    const onCloseModal = (isLoad = false) => {
        if (isLoad) loadData();
        setIsRenderModalW39F3001(false);
    };

    const changeSelectedGoalPlan = (newGoalPlan = '') => {
        setSelectedGoalPlan(newGoalPlan);
    };

    const exportPDF = async () => {
        setLoading(true);
        await Promise.all([leftCol.current && leftCol.current.htmlToCanvas(), rightCol.current && rightCol.current.htmlToCanvas()]);
        setLoading(false);
        try {
            const { goalListImg, planProgressImg } = canvasHtml.current;
            const myWindow = window.open('', Config.lang('Bang_muc_tieu'));
            myWindow.document.write(
                '<body onload="window.print();window.close();"><img alt="" src="' +
                    goalListImg +
                    '" style="width: calc(100% - 10px); border-radius: 4px; padding: 5px; box-shadow: 0px 2px 2px rgb(0 0 0 / 25%);" /><img alt="" src="' +
                    planProgressImg +
                    '" style="margin-top: 10px; width: 30%; border-radius: 4px; box-shadow: 0px 2px 2px rgb(0 0 0 / 25%);" /></body>'
            );
            myWindow.document.close();
            myWindow.focus();
        } catch (error) {
            Config.popup.show('ERROR', Config.lang('Co_loi_xay_ra_trong_qua_trinh_xu_ly'));
        }
    };

    return (
        <>
            <LoadPanel
                shadingColor="rgba(0,0,0,0.4)"
                position={{ my: 'center', of: '#root' }}
                visible={loading}
                showIndicator={true}
                shading={true}
                showPane={true}
            />
            <div className={'hidden'}>{renderFilter()}</div>
            {isRenderModalW39F3001 &&
                <W39F3001
                    open={isRenderModalW39F3001}
                    tabIndex={tabIndex}
                    iPermission={iPermission}
                    onClose={onCloseModal}
                    selectedGoalPlan={selectedGoalPlan}
                />
            }
            <ActionToolbar title={Config.lang('Muc_tieu1')} alignment={'space-between'} showBorder={true} allwaysTop={false}>
                <Button color={'primary'} viewType={'filled'} text={Config.lang('Them')} startIcon={'Add'} onClick={onAdd} />
                <div>
                    <ButtonIcon
                        className={`${classes.btnActCont}`}
                        color={'primary'}
                        viewType={'text'}
                        name={'Menu'}
                        ref={btnActCont}
                        onClick={onToogleActBtnCont}
                    />
                    <div className={`${classes.actionButtonCont} ${classes.flexGap15}`}>
                        <Button
                            color={'primary'}
                            viewType={'outlined'}
                            text={Config.lang('Xuat_PDF')}
                            startIcon={'Pdf'}
                            disabled={dataTab1.IsReview === 0}
                            onClick={exportPDF}
                        />
                        <Button
                            color={'primary'}
                            viewType={'outlined'}
                            text={Config.lang('Tao_review')}
                            startIcon={<InlineSVG src={require('./icons/review.svg')} width={24} height={24} />}
                            disabled={dataTab1.IsReview === 0}
                            onClick={() => handleOpenW39F3005(true)}
                        />
                        <Button
                            ref={btnColorDes}
                            color={'primary'}
                            viewType={'outlined'}
                            text={Config.lang('Mo_ta_mau_sac')}
                            startIcon={<InlineSVG src={require('./icons/color_description.svg')} width={24} height={24} />}
                            onClick={onToogleColorDes}
                        />
                    </div>
                </div>
            </ActionToolbar>
            <Tabs indicatorColor="primary" textColor="primary" value={tabIndex} onChange={changeTab}>
                <Tab key={0} label={Config.lang('Muc_tieu_ca_nhan')} style={{ textTransform: 'uppercase' }} />
                <Tab key={1} label={Config.lang('Muc_tieu_co_cau_to_chuc')} disabled={iPermission < 1} style={{ textTransform: 'uppercase' }} />
            </Tabs>
            <Row className={classes.row}>
                <Col xs={12} sm={12} md={9} lg={9} className={classes.leftCol}>
                    <W39F3000LeftCol
                        classes={classes}
                        colorStatusArr={colorStatusArr}
                        redirect={redirect}
                        changeLoading={changeLoading}
                        loadData={loadData}
                        filter={filter}
                        selectedGoalPlan={selectedGoalPlan}
                        changeSelectedGoalPlan={changeSelectedGoalPlan}
                        canvasHtml={canvasHtml}
                        ref={leftCol}
                    />
                </Col>
                <Col xs={12} sm={12} md={3} lg={3} className={classes.rightCol}>
                    <W39F3000RightCol
                        tabIndex={tabIndex}
                        classes={classes}
                        current={tabIndex === 0 ? currentEmployee : currentOrgChart}
                        changeCurrent={changeCurrent}
                        selectedGoalPlan={selectedGoalPlan}
                        canvasHtml={canvasHtml}
                        ref={rightCol}
                    />
                </Col>
            </Row>
            <CorePopover
                open={toogleActBtnCont}
                onClose={onToogleActBtnCont}
                anchor={btnActCont}
                width={'fit-content'}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: 16,
                    gap: 10,
                }}
            >
                <Button
                    color={'primary'}
                    viewType={'outlined'}
                    text={Config.lang('Xuat_PDF')}
                    startIcon={'Pdf'}
                    disabled={dataTab1.IsReview === 0}
                    onClick={exportPDF}
                />
                <Button
                    color={'primary'}
                    viewType={'outlined'}
                    text={Config.lang('Tao_review')}
                    startIcon={<InlineSVG src={require('./icons/review.svg')} width={24} height={24} />}
                    disabled={dataTab1.IsReview === 0}
                    onClick={() => handleOpenW39F3005(true)}
                />
                <Button
                    ref={btnColorDes2}
                    color={'primary'}
                    viewType={'outlined'}
                    text={Config.lang('Mo_ta_mau_sac')}
                    startIcon={<InlineSVG src={require('./icons/color_description.svg')} width={24} height={24} />}
                    onClick={onToogleColorDes}
                />
            </CorePopover>
            <CorePopover
                open={toogleColorDes}
                onClose={onToogleColorDes}
                anchor={isShowBtnAct ? btnColorDes2 : btnColorDes}
                width={'fit-content'}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: 16,
                    gap: 10,
                }}
            >
                <Typography type={'p2'} style={{ fontWeight: 'bold' }}>
                    {Config.lang('Trang_thai_muc_tieu')}
                </Typography>
                {colorStatusArr.map((i) => {
                    return (
                        <div key={i.id} className={classes.divColorStatusCont}>
                            <div className={classes.divColorStatus} style={{background: i.colorCode}}/>
                            <Typography type={'p2'}>{i.label}</Typography>
                        </div>
                    );
                })}
            </CorePopover>
            {isOpenW39F3005 && (
                <W39F3005
                    open={isOpenW39F3005}
                    employee={tabIndex === 0 ? currentEmployee : loginEmployee.current}
                    handleOpenW39F3005={handleOpenW39F3005}
                    dataCboGoalTable={dataCboGoalTable}
                />
            )}
            {isOpenPopW39F3002 && (
                <Modal
                    id={'Popup-W39F3002'}
                    title={Config.lang('Thong_tin_ket_qua_then_chot')}
                    fullWidth={true}
                    maxWidth={'xl'}
                    open={isOpenPopW39F3002}
                    onClose={() => handleOpenPopW39F3002()}
                >
                    <Modal.Content>
                        <W39F3002 goalProps={dataW39F3002} />
                    </Modal.Content>
                </Modal>
            )}
            {isOpenW39F3004 && <W39F3004 open={isOpenW39F3004} data={goalData} onClose={handleOpenPop} type={1} />}
        </>
    );
}

export default W39F3000;
