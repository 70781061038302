/**
 * @Copyright 2021 @DigiNet
 * @Author XUANLOC
 * @Create 27/3/2021
 * @Example
 */

import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    dateFilter: {
        position: 'relative',
        color: '#505C80',
        margin: '8px 0 4px',
    },
    labelDateFilter: {
        position: 'absolute',
        top: -4,
        background: 'white',
        padding: '0 2px',
        left: 14,
        fontWeight: 500,
        fontSize: 'calc(14px * 0.75)',
        margin: 0,
        lineHeight: 0.85,
    },
    inputDateFilter: {
        border: '1px solid #505C80',
        borderRadius: 4,
        padding: '10px 14px',
    },
    iconDateFilter: {
        position: 'absolute',
        top: 5,
        right: 3,
    },
    listDateRanges: {
        padding: 15,
        borderTop: '1px solid #7F828E',
        borderBottom: '1px solid #7F828E',
        marginTop: 9,
    },
    divButtonDate: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: 24,
    },
    divSearchButton: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    row: {
        background: '#F5F6F6',
        height: 'calc(100vh - 175px)',
        padding: '15px 0',
        [theme.breakpoints.down('md')]: {
            overflow: 'auto',
        },
        [theme.breakpoints.down(768)]: {
            height: 'calc(100vh - 175px)',
        },
        [theme.breakpoints.down(575)]: {
            height: 'calc(100vh - 225px)',
        },
    },
    leftCol: {
        height: '100%',
        [theme.breakpoints.down(992)]: {
            height: 'auto',
        },
    },
    rightCol: {
        height: '100%',
        overflow: 'auto',
        [theme.breakpoints.down(992)]: {
            height: 'auto',
            marginTop: 20,
        },
    },
    goalPlanCont: {
        height: '100%',
        background: 'white',
        padding: '24px 24px 37px',
        boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)',
        borderRadius: 4,
        position: 'relative',
    },
    goalListCont: {
        height: 'calc(100% - 24px)',
        overflow: 'auto',
    },
    divPlanProgress: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        background: 'white',
        boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)',
        borderRadius: 4,
    },
    divChildPlanProgress: {
        flex: '1 1 33%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        margin: 24,
    },
    divCurrent: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        background: 'white',
        boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)',
        borderRadius: 4,
        marginTop: 5,
        padding: 24,
        gap: 24,
    },
    divOther: {
        position: 'relative',
        margin: '5px 0 2px',
        padding: 24,
        background: 'white',
        boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)',
        borderRadius: 4,
    },
    divListOther: {
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100vh - 715px)',
        overflow: 'auto',
        minHeight: 200,
    },
    other: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        gap: 5,
        cursor: 'pointer',
    },
    divArrowDown: {
        position: 'absolute',
        bottom: 3,
        left: '50%',
    },
    divColorStatusCont: {
        display: 'flex',
        flexDirection: 'row',
        gap: 5,
    },
    divColorStatus: {
        width: 16,
        height: 16,
    },
    divGroupGoalName: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: '10px 0',
        alignItems: 'center',
        borderBottom: '2px solid',
    },
    iconExpandTransition: {
        display: 'inline-flex',
        transition: 'all 0.2s ease-in-out',
    },
    divListGoalCont: {
        overflow: 'auto',
    },
    gridGoal: {
        display: 'grid',
        gridTemplateColumns: 'minmax(300px, 11fr) repeat(4, minmax(150px, 2fr))',
        width: 'fit-content',
        padding: '10px 0',
        alignItems: 'center',
    },
    gridTR: {
        display: 'grid',
        gridTemplateColumns: 'minmax(234px, 11fr) repeat(4, minmax(150px, 2fr))',
        width: 'fit-content',
        padding: '10px 0 0 10px',
        alignItems: 'center',
    },
    divGoal: {
        borderTop: '1px solid #D4D5D9',
        '&:hover': {
            background: '#A2CFFF',
            '& #iconEdit': {
                visibility: 'visible !important',
                cursor: 'pointer',
            },
        },
    },
    centerRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    flexGap5: {
        gap: 5,
    },
    flexGap10: {
        gap: 10,
    },
    flexGap15: {
        gap: 15,
    },
    flexGap20: {
        gap: 20,
    },
    flexColumn: {
        display: 'flex',
        flexDirection: 'column',
    },
    pointerCursor: {
        cursor: 'pointer',
    },
    spanOrg: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#C4C4C4',
        color: 'white',
        width: 32,
        height: 32,
        border: '1px solid white',
        borderRadius: '50%',
        boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)',
        overflow: 'hidden',
        fontWeight: 'bold',
    },
    relativePos: {
        position: 'relative',
    },
    hiddenOverflow: {
        overflow: 'hidden',
    },
    textOverflow: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    paging: {
        position: 'absolute',
        left: 0,
        bottom: 0,
        width: '100%',
        '& .pagePer svg': {
            top: 'calc(50% - 15px)',
        },
    },
    actionButtonCont: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down(641)]: {
            display: 'none',
        },
    },
    btnActCont: {
        display: 'none',
        [theme.breakpoints.down(641)]: {
            display: 'block',
        },
    },
}));
