/***********************************************************************/
/*                                                                     */
/*   Copyright (C) 2020. All rights reserved                           */
/*   License    : https://diginet.com.vn                               */
/*   Author     : RocaChien                                            */
/*                                                                     */
/*   Created    : 09-07-2020 10:13:05.                                 */
/*   Modified   : 09-07-2020 10:13:05.                                 */
/*                                                                     */
/***********************************************************************/

import React, { Component } from "react";
import { connect } from "react-redux";
import { browserHistory } from "react-router";
import _ from "lodash";
import validator from 'validator';

import { bindActionCreators, compose } from "redux";
import withStyles from "@material-ui/core/styles/withStyles";
import {LoadPanel} from "devextreme-react";

import Config from "../../../../config";
import {Col, Image, Row} from "react-bootstrap";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import {TextField} from "../../../common/form-material";
import CDN from "../../../CDN";
import Attachments from "../../../common/attachments/attachments";
import UserImage from "../../../common/user/user-image";
import UserName from "../../../common/user/user-name";

import * as W39F1011Actions from "../../../../redux/W3X/W39F1011/W39F1011_actions";
import * as generalActions  from "../../../../redux/general/general_actions";
import * as mainActions     from "../../../../redux/main/main_actions";
import moment               from "moment";
// import jsPDF                from "jspdf";
import html2canvas                                                                            from "html2canvas";
import NumberFormat                                                                           from "react-number-format";
import Icon                                                                                   from 'diginet-core-ui/icons';
import {Button, FormGroup, Tooltip, TextInput, Dropdown}                                      from 'diginet-core-ui/components';
import {FormHelperText ,Container , FormControl, Grid ,Chip ,InputAdornment} from "@material-ui/core";

/************************************/
/* GLOBAL VARIABLE OR FUNCTIONS     */
/************************************/
const convertToRoman = (num) => {
    const roman = {M: 1000, CM: 900, D: 500, CD: 400, C: 100, XC: 90, L: 50, XL: 40, X: 10, IX: 9, V: 5, IV: 4, I: 1};
    let str = '';

    for (let i of Object.keys(roman)) {
        const q = Math.floor(num / roman[i]);
        num -= q * roman[i];
        str += i.repeat(q);
    }

    return str;
};
const styles = theme => {
    return {
        dateInfo: {
            display: 'flex',
            alignItems: 'center',
        },
        divText: {
            // fontSize: "14px",
            fontWeight: 500,
            // color: '#575757',
        },
        divDateText: {
            fontSize: "14px",
            fontWeight: 400,
            color: '#000000',
        },
        fieldset: {
            display: "flex",
            marginTop: "10px"
        }
    };
};

class W39F1011 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            CriterionTitle: '',
            EmployeeInfo: null,
            errorMessage: false,

            loading: true,
            loading2: false,
            uploading: false,
            getResultSuccess: false,
            iPermission: 0,
            errors: {},
            avatarAttach: null,
            formValid: [],
            dataOldAttachments: [],
            IsSave: 1,
            criterionCboResultsError: {},
        };
        this.isLoaded = false;
        this.dataDetail = null;
        this.attachments = [];
        this.deletedFile = [];
        this.upload = null;
        this.timeout = null;
        this.EvaluationCombo = {};
        this.UserLevel = 1;
        this.TransTypeID = "";
        this.formData ={
            Mode: 0,
            ApproveLevel: 0,
            TransID: "",
            EmployeeID: "",
            DivisionID: "",
            EvaluationVoucherID: "",
            TranMonth: "",
            TranYear: "",
            Language: "",
            NoteEmployee: null,
            NoteEvaluatedLevel2: null,
            NoteEvaluatedLevel3: null,
            arrAttachment: [],
            ResultID1: null,
            ResultID2: null,
            ResultID3: null,
            detail: []
        };
        this.contentContainer = React.createRef();
        this.loaded = {};
        this.canvasHtml = null;
        this.isChangeForm = false;
        this.btnSaveRef = {};
    }
    componentDidMount = async () => {

        const { location } = this.props;
        const Language = Config.language || "84";
        const DivisionID = Config.getDivisionID();
        const Mode = _.get(location, "state.Mode", null);
        const FormID = _.get(location, "state.FormID", "W39F1011");
        const FormPermission = _.get(location, "state.FormPermission", ""); //FormID for get permission...
        const EvaluationVoucherID = _.get(location, "state.EvaluationVoucherID", "");
        const TransID = _.get(location, "state.TransID", "");
        const TransTypeID = _.get(location, "state.TransTypeID", "");
        const EmployeeID = _.get(location, "state.EmployeeID", "");
        const ApproveLevel = _.get(location, "state.ApproveLevel", null);
        this.UserLevel = _.get(location, "state.UserLevel", 1);
        const IsSave = _.get(location, "state.IsSave", 1);
        this.TransTypeID = TransTypeID;

        await this.loadPermission(FormPermission ? FormPermission : FormID);
        if (!this.state.iPermission) return;

        if (
            ApproveLevel === null ||
            ![0, 1].includes(Mode) ||
            !FormID || FormID.length === 0 ||
            !TransID || TransID.length === 0 ||
            !EmployeeID || EmployeeID.length === 0 ||
            !EvaluationVoucherID || EvaluationVoucherID.length === 0
        ) {
            this.setState({ errorMessage: Config.lang("Khong_tim_thay_hoac_khong_du_tt") });
            return null;
        }

        this.formData.Mode = Mode;
        this.formData.ApproveLevel = ApproveLevel;
        this.formData.DivisionID = DivisionID;
        this.formData.Language = Language;
        this.formData.TransID = TransID;
        this.formData.FormID = FormID;
        this.formData.EmployeeID = EmployeeID;
        this.formData.EvaluationVoucherID = EvaluationVoucherID;
        this.formData.TranMonth = Config.getHRTransMonth();
        this.formData.TranYear = Config.getHRTransYear();

        // Show loading and hide when timeout
        this.setState({
            IsSave: IsSave,
            loading: true
        });
        this.timeout = setTimeout(()=>{
            this.isLoaded = true;
            this.setState({ loading: false }, () => {
                this.html2canvas();
            });
        }, 3500);

        this.loaded = {
            loadMasterData: false,
            loadCriterionGroup: false,
            loadCriterionDetail: false,
            loadCriterionCombo: false,
        };
        // Call list APIs
        this.loadApi("loadMasterData", {FormID, Language, Mode, EvaluationVoucherID, TransID, EmployeeID, TransTypeID}, true);
        this.loadApi("loadCriterionGroup", {}, true);
        this.loadApi("loadCriterionDetail", {FormID, EvaluationVoucherID, TransID, EmployeeID, TransTypeID }, true);
        this.loadApi("loadCriterionCombo", {FormID, EvaluationVoucherID, EmployeeID, TransTypeID}, true);
        this.loadApi("loadHistoryApproval", { FormID, TransID, Language }, true);
        this.loadApi("getAttachmentsByTransID", { KeyID: TransID, Key02ID: EmployeeID });
        this.loadApi("getRequiredFields", {FormID: 'W39F1011', ModuleID: '39', CheckMode: 0 }, false);
    };
    componentWillUnmount = () => {
        this.setState({
            CriterionTitle: '',
            EmployeeInfo: null,
            errorMessage: false,

            loading: true,
            uploading: false,
            iPermission: 0,
            errors: {},
            avatarAttach: null,
            dataOldAttachments: [],
            IsSave: 1,
        });
        this.isLoaded = false;
        this.dataDetail = null;
        this.attachments = [];
        this.deletedFile = [];
        this.upload = null;
        this.EvaluationCombo = {};
        this.UserLevel = 2;
        this.formData ={
            Mode: 0,
            ApproveLevel: 0,
            TransID: "",
            EmployeeID: "",
            DivisionID: "",
            EvaluationVoucherID: "",
            TranMonth: "",
            TranYear: "",
            Language: "",
            NoteEmployee: null,
            NoteEvaluatedLevel2: null,
            NoteEvaluatedLevel3: null,
            arrAttachment: [],
            detail: []
        };
        this.btnSaveRef = {};

        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    };
    loadPermission = async (FormID) => {
        const { location } = this.props;
        if(location?.state?.menu?.Type === "ESS" || FormID === "W39F2000"){
            this.setState({ iPermission: 4, loading: false });
            return await true;
        }
        await this.props.generalActions.getPermission(FormID ? FormID : "W39F1011", isPer => {
            this.setState({ iPermission: isPer, loading: false });
        }, FormID === "W39F2000");
    };
    onBack = () => {
        // browserHistory.goBack();
        const { location } = this.props;
        const FormID = _.get(location, "state.FormID", "W39F1010");
        let state = _.get(location, "state", {});
        state = {
            ...state,
            FormBack: "W39F2011"
        };

        browserHistory.push({
            pathname: Config.getRootPath() + FormID,
            state: state
        });
    };
    loadApi = (apiName, params = {}, ownAction = false) => {
        this.props[ownAction ? "W39F1011Actions" : "generalActions"][apiName](params, (error, data = null) => {
            if (error) {
                Config.popup2.show("ERROR", error);
                return false;
            }

            this.loaded[apiName] = true;
            this.html2canvas();

            if (apiName === "loadMasterData" && data) {
                if (data.IsEvaluateContract === 1) {
                    this.loadApi("loadComboResults", {Language: Config.language || "84"}, true);
                }
                if (data.EmployeeID) {
                    this.setState({
                        EmployeeInfo: Config.getUser({EmployeeID: data.EmployeeID}),
                        CriterionTitle: data.TransTypeName,
                        Note: data.Note || "",
                    });
                }
            }
            if (apiName === "getAttachmentsByTransID" && !_.isEmpty(data)) {
                if (data && data.length > 0) {
                    this.setState({
                        dataOldAttachments: data
                    });
                }
            }
        });
    };
    html2canvas = () => {
        if (this.canvasTimer) clearTimeout(this.canvasTimer);
        this.canvasTimer = setTimeout(() => {
            if (this.loaded) {
                const isChecked = Object.keys(this.loaded).find(l => !this.loaded[l]);
                const input = this.contentContainer.current;
                if (!isChecked && input) {
                        const svgElements = input.querySelectorAll('svg');
                        svgElements.forEach(function(item) {
                            item.setAttribute("width", item.getBoundingClientRect().width);
                            item.setAttribute("height", item.getBoundingClientRect().height);
                            item.style.width = null;
                            item.style.height= null;
                        });

                        this.canvasHtml = input.cloneNode(true);
                }
            }
        }, 2000);
    };

    /************************************/
    /* FORM MODULE FUNCTIONS            */
    /************************************/
    setStateErrorText(objValue, key) {
        const stateError = key ? key : "errors";
        this.setState({
            [stateError]: { ...(this.state[stateError] ? this.state[stateError] : {}), ...objValue }
        });
        return Object.keys(objValue).length !== 0;
    }
    deletedAttachment = URL => {
        if (!_.isEmpty(URL) && this.deletedFile.filter(e => e.URL === URL).length === 0)
            this.deletedFile = [...this.deletedFile, { URL }];
    };
    onRemoveFile = () => {
        const { getForm } = this.props;
        const UserPictureURL = _.get(getForm, "UserPictureURL", "");
        this.deletedAttachment(UserPictureURL);
        this.setState({
            avatarAttach: null,
            formData: {
                ...this.state.formData,
                UserPictureURL: ""
            }
        });
    };
    ignoreReminder = () => {
        this.props.mainActions.ignoreReminder(_.get(this.props, "location.state.dataReminder", {}), (err) => {
            if (err) {
                Config.popup2.show("ERROR", err);
                return false;
            }
        })
    }
    checkDataWarning = () => {
        if (this.dataDetail) {
            const {Mode} = this.formData;
            let isNotUpdated = false;
            for (let i = 0; i < this.dataDetail.length; i++) {
                const detail = this.dataDetail[i];
                if (detail.IsTypeEvaluation === 0 && Mode === 0) {
                    if (!detail.Value && !detail.IsUpdated && this.UserLevel === 1) isNotUpdated = true;
                    if (!detail.Value02 && !detail.IsUpdated02 && this.UserLevel === 2) isNotUpdated = true;
                    if (!detail.Value03 && !detail.IsUpdated03 && this.UserLevel === 3) isNotUpdated = true;
                    if (isNotUpdated) break;
                } else if (detail.IsTypeEvaluation === 1) {
                    if (!detail.Value && !detail.IsUpdated && this.UserLevel === 1) isNotUpdated = true;
                    if (!detail.Value02 && !detail.IsUpdated02 && this.UserLevel === 2) isNotUpdated = true;
                    if (!detail.Value03 && !detail.IsUpdated03 && this.UserLevel === 3) isNotUpdated = true;
                    if (isNotUpdated) break;
                }
            }
            return isNotUpdated;
        }
        return true;
    };
    onSave = () => {
        // console.log('=== onSave ===');
        const {dataMasterData, getRequiredFields} = this.props;
        let LabelLevel1 = dataMasterData?.LabelLevel1 ? dataMasterData.LabelLevel1 :'';
        let LabelLevel2 = dataMasterData?.LabelLevel2 ? dataMasterData.LabelLevel2 :'';
        let LabelLevel3 = dataMasterData?.LabelLevel3 ? dataMasterData.LabelLevel3 :'';
        const dataRequireFields = [];
        // Step 2: Check form valida
        if (this.state.formValid && this.state.formValid.length > 0) {
            Config.popup2.show("INFO", Config.lang("Tham_so_truyen_vao_khong_hop_le"));
            return false;
        }

        //Step 2.1: Check results when isEvaluateContract..
        if (dataMasterData && dataMasterData.IsEvaluateContract === 1) {
            let isCheckResultValid = true;
            const {ResultID1, ResultID2, ResultID3} = this.formData;
            let criterionCboResultsError = {};
            switch (this.UserLevel) {
                case 1:
                    if (!ResultID1) isCheckResultValid = false;
                    criterionCboResultsError.ResultID1 = true;
                    break;
                case 2:
                    if (!ResultID2) isCheckResultValid = false;
                    criterionCboResultsError.ResultID2 = true;
                    break;
                case 3:
                    if (!ResultID3) isCheckResultValid = false;
                    criterionCboResultsError.ResultID2 = true;
                    break;
                default:
                    break;
            }
            if (!isCheckResultValid) {
                this.setState({criterionCboResultsError: criterionCboResultsError});
                Config.popup2.show("INFO", Config.lang("Ban_phai_chon_ket_qua_danh_gia"));
                return false;
            }
        }
        // check require field
        getRequiredFields && getRequiredFields.forEach(field => {
            if(field?.ValidMode  === "O" &&
                this.formData.hasOwnProperty(field?.SqlFieldName) &&
                this.formData[field?.SqlFieldName] === "")
            {
                if(field?.SqlFieldName?.includes(2) && this.UserLevel === 2) {
                    dataRequireFields.push(LabelLevel2)
                } else if (field?.SqlFieldName?.includes(3) && this.UserLevel === 3) {
                    dataRequireFields.push(LabelLevel3)
                } else if (isNaN(field?.SqlFieldName?.substr(-1)) && this.UserLevel === 1) {
                    dataRequireFields.push(LabelLevel1);
                }
            }
        });


        if(!_.isEmpty(dataRequireFields)) {
            let message = Config.lang("TruongB") + " " +
                dataRequireFields[0] + " " + Config.lang("Bat_buoc_nhapB").toLowerCase();
            Config.popup2.show("INFO", message);
            return false;
        }

        // Step 2.2: Check form warning
        if (this.checkDataWarning()) {
            Config.popup2.show({
                yesText: Config.lang("Dong_y"),
                noText: Config.lang("Bo_qua"),
                type: "yesno"},
                Config.lang("Vui_long_kiem_tra_lai_Ban_da_danh_gia_day_du_cac_chi_tieu_chua"),
                null, () => {
                this._saveData();
            });
        } else {
            this._saveData();
        }

    };
    _saveData = () => {
        const {location, getRequiredFields, dataMasterData} = this.props;
        let LabelLevel1 = dataMasterData?.LabelLevel1 ? dataMasterData.LabelLevel1 :'';
        let LabelLevel2 = dataMasterData?.LabelLevel2 ? dataMasterData.LabelLevel2 :'';
        let LabelLevel3 = dataMasterData?.LabelLevel3 ? dataMasterData.LabelLevel3 :'';
        const dataWarningFields = [];
        // Step 1: Convert property CriterionDetail object to detail array
        this.formData.TransTypeID = this.TransTypeID;
        this.formData.detail = this.dataDetail;
        this.formData.arrAttachment = this._getAttachments();

        // check warning field
        getRequiredFields && getRequiredFields.forEach(field => {
            if(field?.ValidMode  === "W" &&
                this.formData.hasOwnProperty(field?.SqlFieldName) &&
                this.formData[field?.SqlFieldName] === "")
            {
                if(field?.SqlFieldName?.includes(2) && this.UserLevel === 2) {
                    dataWarningFields.push(LabelLevel2)
                } else if (field?.SqlFieldName?.includes(3) && this.UserLevel === 3) {
                    dataWarningFields.push(LabelLevel3)
                } else if (isNaN(field?.SqlFieldName?.substr(-1)) && this.UserLevel === 1) {
                    dataWarningFields.push(LabelLevel1);
                }
            }
        });

        if(!_.isEmpty(dataWarningFields)) {
            let message = dataWarningFields[0] + " " + Config.lang("Chua_duoc_nhap_ban_co_muon_luu_khong");
            Config.popup2.show({
                yesText: Config.lang("Tiep_tuc"),
                noText: Config.lang("Khong"),
                type: "yesno"},
                message,
                () => {
                    this.confirmSaveData(this.formData, location)
                }, null);
        } else {
            this.confirmSaveData(this.formData, location)
        }

    };

    confirmSaveData = (dataForm, location) => {
        // Step 3: Submit to server
        this.setState({loading2: true});
        this.props.W39F1011Actions["save"](dataForm, (error, data) => {
            this.setState({loading2: false});
            if (error) {
                let message = Config.lang("Loi_chua_xac_dinh");
                switch (error.code) {
                    case "ERR000":
                        message = "DivisionID " + Config.lang("Bat_buoc");
                        break;
                    case "RGE002":
                        message = "TransID " + Config.lang("Bat_buoc");
                        break;
                    case "ERR003":
                        message = "CriterionDetail " + Config.lang("Bat_buoc");
                        break;
                    case "ERR006":
                        message = "Attachment " + Config.lang("Luu_khong_thanh_cong");
                        break;
                    case "null":
                        message = "URL " + Config.lang("Bat_buoc");
                        break;
                    default:
                        message = Config.lang("Luu_khong_thanh_cong");
                        break;
                }
                Config.popup2.show("INFO", message);
                return false;
            }

            if (data?.Status === 1) {
                let message = data.Message || Config.lang("Loi_chua_xac_dinh");
                Config.popup2.show("INFO", message);
            }
            else if (data?.Status === 0) {
                this.timeout = setTimeout(()=>{
                    this.isChangeForm = false;
                    const {Mode, Language, TransID, FormID, EmployeeID, EvaluationVoucherID} = this.formData;
                    const TransTypeID = this.TransTypeID;
                    this._removeCDN();
                    this.loaded = {
                        ...this.loaded,
                        loadMasterData: false,
                        loadCriterionGroup: false,
                        loadCriterionDetail: false,
                    };
                    this.canvasImage = null;
                    this.loadApi("loadMasterData", {FormID, Language, Mode, EvaluationVoucherID, TransID, EmployeeID, TransTypeID}, true);
                    this.loadApi("loadCriterionGroup", {}, true);
                    this.loadApi("loadCriterionDetail", {FormID, EvaluationVoucherID, TransID, EmployeeID, TransTypeID }, true);
                    this.loadApi("getRequiredFields", {FormID: 'W39F1011', ModuleID: '39', CheckMode: 0 }, false);
                    // this.loadApi("getAttachmentsByTransID", { KeyID: TransID, Key02ID: EmployeeID });
                    // this.onBack();
                    setTimeout(() => {
                        this.isSaved = true;
                    }, 500); //delay 1s wait for data is loaded
                }, 700);
                Config.notify.show("success", Config.lang("Luu_thanh_cong"), 2000);
                if(_.get(location,"state.dataReminder.KeyID", false)) this.ignoreReminder();
            }
        });
    };
    onChange = (e, name, data, isGetCalResult = false) => {
        const value = _.get(e, "value", _.get(e, "target.value", "")) || e;
        // console.log('=== value: ', value);
        // console.log('=== name: ', name);
        // console.log('=== data: ', data);

        if (["NoteEmployee", "NoteEvaluatedLevel2", "NoteEvaluatedLevel3", "ResultID1", "ResultID2", "ResultID3"].includes(name)) {
            // Change value of master data
            this.formData[name] = value;
        } else if (data) {
            // Change value of detail data
            for (let i = 0; i < this.dataDetail.length; i++) {
                const detail = this.dataDetail[i];

                if (
                    data.EvaluationElementID &&
                    detail.EvaluationElementID &&
                    data.EvaluationElementID === detail.EvaluationElementID
                ) {
                    // Trong trường hợp là combo thì update lại data
                    if ( _.isString(value) && value.includes("~")) {
                        const vl = value.split("~");
                        switch (name) {
                            case "Value02":
                                detail["Type02ID"] = vl[0];
                                detail["IsUpdated02"] = 1;
                                break;
                            case "Value03":
                                detail["Type03ID"] = vl[0];
                                detail["IsUpdated03"] = 1;
                                break;
                            default:
                                detail["TypeID"] = vl[0];
                                detail["IsUpdated"] = 1;
                                break;
                        }

                        detail[name] = vl[1];
                    } else {
                        switch (name) {
                            case "Value02":
                                detail["IsUpdated02"] = 1;
                                break;
                            case "Value03":
                                detail["IsUpdated03"] = 1;
                                break;
                            default:
                                detail["IsUpdated"] = 1;
                                break;
                        }
                        if (isGetCalResult) {
                            switch (this.formData.ApproveLevel) {
                                case 1:
                                    detail[name] = value;
                                    break;
                                case 2:
                                    detail["Value02"] = value;
                                    detail["IsUpdated02"] = 1;
                                    break;
                                case 3:
                                    detail["Value03"] = value;
                                    detail["IsUpdated03"] = 1;
                                    break;
                                default:
                                    break;
                            }
                        } else {
                            detail[name] = value;
                        }
                    }
                }

                this.dataDetail[i] = detail;
            }
        }
        if (isGetCalResult) this.setState({ getResultSuccess: true }); // Mục đích reRender UI
        if (!this.isChangeForm && !_.isEmpty(this.btnSaveRef)) {
            this.isChangeForm = true;
            Object.keys(this.btnSaveRef).forEach(btn => {
                if (btn && this.btnSaveRef[btn]) this.btnSaveRef[btn].instance.option("disabled", false)
            });
        }
    };
    onBlur = async (value, name, rule, data, num) => {
        // console.log('=== onBlur ===');

        if (rule === "Point") {
            this.inputFocus = null;
            await this.validCriterionPoint(name, value, data, num);
        }
    };
    validCriterionPoint = async (name, value, data, num) => {
        const {formValid} = this.state;
        // console.log('==== validCriterionPoint ====');
        // console.log('==== value: ', value);
        // console.log('==== data: ', data);
        const val = value ? value : "0";
        const Decimals = data.Decimals || 0;
        let _formValid = formValid.filter(f => !(f.field === name && f.criterionID === data.EvaluationElementID))
        let isValid = true;
        if (Number(Decimals) > 0) {
            isValid = validator.isDecimal(val, {decimal_digits: Decimals, locale: 'vi-VN'});
        } else {
            isValid = validator.isInt(val, {min: 0});
        }

        if (!isValid) {
            _formValid.push({
                field: name,
                criterionID: data.EvaluationElementID,
                error: true,
                message: Config.lang("Diem_so_khong_hop_le")
            });
        } else {
            if (Number(data.MaxValue) > 0 && Number(val) > Number(data.MaxValue)) {
                _formValid.push({
                    field: name,
                    criterionID: data.EvaluationElementID,
                    error: true,
                    message: Config.lang("Gia_tri_lon_nhat") + ": " + data.MaxValue
                });
            } else {
                if (data.EvaluationElementID && (data["Value" + (num ? num : "")] !== value)) {
                    const newType = await this.props.W39F1011Actions.getTypeEvaluation({
                        EvaluationElementID: data.EvaluationElementID,
                        Value: value || 0
                    });
                    if (newType) {
                        const ID = "Type" + (num ? num : "") + "ID";
                        const Name = "Type" + (num ? num : "") + "Name";
                        this.dataDetail = this.dataDetail.map(d => {
                            if (d.EvaluationElementID === data.EvaluationElementID) {
                                d[ID] = newType.EvaluationLevelID;
                                d[Name] = newType.EvaluationLevelName;
                            }
                            return d;
                        });
                    }
                }
            }
        }

        this.setState({formValid: _formValid});
    };
    isError = (name, rule, data) => {
        // console.log('=== onBlur ===');
        // console.log('=== rule: ', rule);
        // console.log('=== name: ', name);
        // console.log('=== data: ', data);

        const {formValid} = this.state;
        if (rule === "Point") {
            for (let i = 0; i < formValid.length; i++) {
                const fvl = formValid[i];

                if (fvl.field === name && fvl.criterionID === data.EvaluationElementID) {
                    return fvl.error;
                }
            }
        }

        return false;
    };
    getErrorMessage = (name, rule, data) => {
        // console.log('=== onBlur ===');
        // console.log('=== rule: ', rule);
        // console.log('=== name: ', name);
        // console.log('=== data: ', data);

        const {formValid} = this.state;

        if (rule === "Point") {
            for (let i = 0; i < formValid.length; i++) {
                const fvl = formValid[i];

                if (fvl.field === name && fvl.criterionID === data.EvaluationElementID) {
                    return fvl.message;
                }
            }
        }

        return "";
    };

    /************************************/
    /* ATTACHMENT FUNCTIONS             */
    /************************************/
    onAttachment = () => {
        if (this.attRef) {
            this.attRef.onAttachment();
        }
    };
    onChangeAttachments = (e) => {
        this.isChangeForm = true;

        this.attachments = e.uploadedFiles ? e.uploadedFiles : [];
        if (e.deletedFiles && e.deletedFiles.length > 0) {
            this.deletedFile = [...e.deletedFiles];
            const _arrRemove = this.deletedFile.map((d) => d.AttachmentID);
            this.setState((prevState) => ({
                dataOldAttachments: prevState.dataOldAttachments.filter((att) => {
                    return _arrRemove.indexOf(att.AttachmentID) < 0;
                }),
            }));
        }
    };
    onUploading = value => {
        this.setState({ uploading: value });
    };
    _removeCDN = () => {
        if (this.deletedFile && this.deletedFile.length > 0) {
            this.deletedFile.forEach((e) => {
                const path = e.URL.split("=");
                if (path && path.length > 1) {
                    const params = {
                        path: path[1],
                    };
                    CDN.removeFile(params);
                }
            });
            this.deletedFile = [];
        }
    };
    _getAttachments = () => {
        const listAttachments = Config.helpers.getFileInfomations(this.attachments);
        const { dataOldAttachments } = this.state;
        let arrAttachment = [];
        listAttachments.forEach((att) => {
            arrAttachment.push({
                URL: att.url ? att.url : "",
                FileName: att.fileName ? att.fileName : "",
                FileSize: att.fileSize ? att.fileSize : "",
                FileExt: att.fileExt ? att.fileExt : "",
            });
        });
        if (!_.isEmpty(dataOldAttachments)) {
            arrAttachment = dataOldAttachments.concat(arrAttachment);
        }

        return arrAttachment;
    };

    /************************************/
    /* RENDER FUNCTIONS                 */
    /************************************/
    parseDataGroupCriterion = () => {
        const { dataMasterData, dataCriterionGroup, dataCriterionDetail, dataCriterionCombo } = this.props;

        if (
            !dataMasterData ||
            !dataCriterionGroup ||
            dataCriterionGroup.length === 0 ||
            !dataCriterionDetail ||
            dataCriterionDetail.length === 0 ||
            !this.isLoaded
        ) {
            return null;
        }

        if (dataCriterionCombo && dataCriterionCombo.length > 0) {
            for (let i = 0; i < dataCriterionDetail.length; i++) {
                const dt = dataCriterionDetail[i];
                const sl = [];


                for (let j = 0; j < dataCriterionCombo.length; j++) {
                    const cb = dataCriterionCombo[j];

                    if (dt.EvaluationElementID === cb.EvaluationElementID) {
                        // We need 2 field to save, and merge its into the value with ~
                        sl.push({text: cb.EvaluationCodeName, value: cb.EvaluationCodeID+"~"+cb.Value});
                    }
                }

                if (sl.length > 0) {
                    this.EvaluationCombo[dt.EvaluationElementID] = sl;
                }
            }
        }

        const criterion = [];
        const dataDetail = [];
        for (let i = 0; i < dataCriterionGroup.length; i++) {
            const group = dataCriterionGroup[i];
            const grCriterion = [];

            for (let j = 0; j < dataCriterionDetail.length; j++) {
                const detail = dataCriterionDetail[j];

                if (group.AppCriterionGroupID === detail.AppCriterionGroupID) {
                    grCriterion.push(detail);
                    dataDetail.push({
                        TypeID: detail.TypeID,
                        TypeName: detail.TypeName,
                        Value: detail.Value,
                        IsUpdated: 0,
                        Type02ID: detail.Type02ID,
                        Type02Name: detail.Type02Name,
                        Value02: detail.Value02,
                        IsUpdated02: 0,
                        Type03ID: detail.Type03ID,
                        Type03Name: detail.Type03Name,
                        Value03: detail.Value03,
                        IsUpdated03: 0,
                        Note1U: detail.Note1U,
                        Note2U: detail.Note2U,
                        Note3U: detail.Note3U,
                        IsTypeEvaluation: detail.IsTypeEvaluation,
                        EvaluationElementID: detail.EvaluationElementID,
                    });
                }
            }

            if (grCriterion.length > 0) {
                group.ListCriterionDetail = grCriterion;
                criterion.push(group);
            }
        }

        //set Result value when IsEvaluateContract = 1...
        if (dataMasterData.IsEvaluateContract === 1) {
            const {ResultID1, ResultID2, ResultID3} = this.formData;
            if (ResultID1 === null) {
                this.formData.ResultID1 = dataMasterData && dataMasterData.ResultID1 ? dataMasterData.ResultID1 : '';
            }
            if (ResultID2 === null) {
                this.formData.ResultID2 = dataMasterData && dataMasterData.ResultID2 ? dataMasterData.ResultID2 : '';
            }
            if (ResultID3 === null) {
                this.formData.ResultID3 = dataMasterData && dataMasterData.ResultID3 ? dataMasterData.ResultID3 : '';
            }
        }

        // For push data form props to this
        if (!this.dataDetail || this.isSaved) {
            this.dataDetail = [...dataDetail];
            if (this.isSaved) setTimeout(() => this.isSaved = false, 1000); //clear isSave when reload data;

            this.formData.NoteEmployee = dataMasterData && dataMasterData.NoteEmployee ? dataMasterData.NoteEmployee : '';
            this.formData.NoteEvaluatedLevel2 = dataMasterData && dataMasterData.NoteEvaluatedLevel2 ? dataMasterData.NoteEvaluatedLevel2 : '';
            this.formData.NoteEvaluatedLevel3 = dataMasterData && dataMasterData.NoteEvaluatedLevel3 ? dataMasterData.NoteEvaluatedLevel3 : '';
            // For the first time get data from redux
            // this.setState({ loading: false });
        }

        return criterion;
    };
    renderInputAdorment = (value) => {
        return <InputAdornment disableTypography={true}
                               component={"span"}
                               title={value || ""}
                               style={{whiteSpace: "nowrap", maxWidth: "50%", height: "100%"}}
                               position="end">
            <span style={{width: "100%", overflow: "hidden", textOverflow: "ellipsis"}}>{value || ""}</span>
        </InputAdornment>
    };
    renderUserProfile = (data) => {
        const {classes, dataMasterData} = this.props;
        const AppraisalDate = dataMasterData.AppraisalDate ? Config.convertDate(dataMasterData.AppraisalDate, "", "DD/MM/YYYY kk:mm:ss", true) : "";

        return (
            <div className={"display_row align-center pdt10 pdb10"}>
                <UserImage data={data} onClick={() => this.onChangePageProfile(data)}/>
                <div className={"w75f2000_info"}>
                    <div className={classes.divText}><UserName data={data}/></div>

                    <div className={classes.dateInfo}>
                        <Image src={require('../../../../assets/images/icon-calendar.svg')}/>
                        <div style={{paddingLeft:'8px'}} className={"date-text-info"}>{AppraisalDate}</div>
                    </div>
                    {dataMasterData && dataMasterData.IsEvaluateContract === 1 &&
                    <div className={classes.divText}>{dataMasterData.WorkFormName}</div>}
                </div>
            </div>
        )
    };


    renderCriterionCboResults = (name, disabled) => {
        if (!name || name.length < 1) {
            return null;
        }

        const {criterionCboResultsError} = this.state;
        const {dataComboResults} = this.props;
        let selected = this.formData && this.formData[name] ? this.formData[name] : "";
        let errorMessage = criterionCboResultsError[name] && !selected ? Config.lang("Vui_long_chon_ket_qua") : "";

        return (
            <FormControl error={!!errorMessage} fullWidth>
                <Dropdown
                    required
                    clearAble
                    dataSource={dataComboResults}
                    disabled={disabled}
                    displayExpr={"ResultName"}
                    valueExpr={"ResultID"}
                    defaultValue={selected || ""}
                    onChange={(e) => {
                        const value = e.value;
                        this.onChange(value, name);
                        if (value) this.setState({criterionCboResultsError: {}});
                    }}
                />
                {errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
            </FormControl>
        );
    };
    renderCriterionCombo = (code, name, detail, disabled) => {
        if (!code || code.length < 1 || !this.EvaluationCombo[code]) {
            return null;
        }

        let data = [];

        if (this.EvaluationCombo[code] && this.EvaluationCombo[code].length > 0) {
            data = this.EvaluationCombo[code];
        }

        let selected = "";
        switch (name) {
            case "Value02":
                selected = detail.Type02ID && detail.Type02ID !== "NaN" ? detail.Type02ID+"~"+detail.Value02 : null;
                break;
            case "Value03":
                selected = detail.Type03ID && detail.Type03ID !== "NaN" ? detail.Type03ID+"~"+detail.Value03 : null;
                break;
            default:
                selected = detail.TypeID && detail.TypeID !== "NaN" ? detail.TypeID+"~"+detail.Value : null;
                break;
        }

        return (
            <FormControl fullWidth>
                <Dropdown
                    clearAble
                    dataSource={data}
                    disabled={disabled}
                    displayExpr={"text"}
                    valueExpr={"value"}
                    defaultValue={selected || ""}
                    onChange={(e) => this.onChange(e.value || "~0", name, detail)}
                />
            </FormControl>
        );
    };
    _setDefaultDetail = (value) => {
        return !this.isChangeForm ? value : typeof value === "number" ? 0 : "";

    };
    renderCriterionDetail = (data, level) => {
        let pdLevel1 = {padding: "0"};
        let pdLevel2 = {padding: "0"};
        let pdLevel3 = {padding: "0"};

        if (level === 2) {
            pdLevel1 = {padding: "0 10px 0 0"};
            pdLevel2 = {padding: "0 0 0 10px"};
            pdLevel3 = {padding: "0"};
        }
        if (level === 3) {
            pdLevel1 = {padding: "0 10px 0 0"};
            pdLevel2 = {padding: "0 10px 0 10px"};
            pdLevel3 = {padding: "0 0 0 10px"};
        }

        let typeNum = 0;
        let typeText = data.ResultDes || "";
        if (data && data.IsTypeEvaluation) {
            typeNum = parseInt(data.IsTypeEvaluation);
            typeNum = typeNum > 2 || typeNum < 0 ? 0 : typeNum;
        }
        if (!typeText) {
            switch (typeNum) {
                case 1:
                    typeText = Config.lang("Xep_loai");
                    break;
                case 2:
                    typeText = Config.lang("Cong_thuc");
                    break;
                default:
                    typeText = Config.lang("Diem");
                    break;
            }
        }

        // Get data for change
        let changed = {};
        for (let i = 0; i < this.dataDetail.length; i++) {
            const detail = this.dataDetail[i];

            if (
                data.EvaluationElementID &&
                detail.EvaluationElementID &&
                data.EvaluationElementID === detail.EvaluationElementID
            ) {
                changed = detail;
            }
        }

        // For detail data with submitting form
        const detail = {
            TypeID: !Config.isEmpty(changed.TypeID) ? changed.TypeID : this._setDefaultDetail(data.TypeID),
            TypeName: !Config.isEmpty(changed.TypeName) ? changed.TypeName : this._setDefaultDetail(data.TypeName),
            Value: !Config.isEmpty(changed.Value) ? changed.Value : this._setDefaultDetail(data.Value),
            Type02ID: !Config.isEmpty(changed.Type02ID) ? changed.Type02ID : this._setDefaultDetail(data.Type02ID),
            Type02Name: !Config.isEmpty(changed.Type02Name) ? changed.Type02Name : this._setDefaultDetail(data.Type02Name),
            Value02: !Config.isEmpty(changed.Value02) ? changed.Value02 : this._setDefaultDetail(data.Value02),
            Type03ID: !Config.isEmpty(changed.Type03ID) ? changed.Type03ID : this._setDefaultDetail(data.Type03ID),
            Type03Name: !Config.isEmpty(changed.Type03Name) ? changed.Type03Name : this._setDefaultDetail(data.Type03Name),
            Value03: !Config.isEmpty(changed.Value03) ? changed.Value03 : this._setDefaultDetail(data.Value03),
            Note1U: !Config.isEmpty(changed.Note1U) ? changed.Note1U : this._setDefaultDetail(data.Note1U),
            Note2U: !Config.isEmpty(changed.Note2U) ? changed.Note2U : this._setDefaultDetail(data.Note2U),
            Note3U: !Config.isEmpty(changed.Note3U) ? changed.Note3U : this._setDefaultDetail(data.Note3U),
            EvaluationElementID: data.EvaluationElementID,
            MaxValue: data.MaxValue ? data.MaxValue : 0,
            Decimals: data.Decimals ? data.Decimals : 0,
        };

        return (
            <span key={data.EvaluationElementID} style={{display: 'flex', alignItems: 'center'}}>
                <Grid
                key={Math.random().toString(36).substring(7)}
                container
                spacing={0}
                direction="row"
                justify="flex-end"
                alignItems="flex-start"
                style={{marginBottom: 20}}>
                <Grid item xs={10} style={{padding: "0 auto"}}>
                    <div>{data.OrderNo + ". " + data.EvaluationElementName}</div>
                    {data.Note && <div style={{whiteSpace: 'pre-line', fontStyle: "italic", fontSize: 13}}>{data.Note}</div>}
                </Grid>
                <Grid item xs={2} style={{padding: "0 auto"}}>
                    <div className={"display_row align-center valign-bottom"}>
                        {data && Number(data.Proportion) > 0 && <Chip size="small" label={String(data.Proportion)} className={"mgr5"} />}
                        <div style={{textAlign:"right"}}>({typeText})</div>
                    </div>
                </Grid>
                <hr style={{marginTop: 3, marginBottom: 5, width: "100%"}} />

                {level >= 1 &&
                <Grid item xs={12/level} style={pdLevel1}>
                    {typeNum === 0 &&
                    <NumberFormat
                        customInput={TextField}
                        thousandSeparator={false}
                        decimalScale={detail.Decimals || 0}
                        // value={state[dataField] ? String(state[dataField]) : dataOldForm['TempBaseSalary' + no]}
                        fullWidth
                        disabled={this.UserLevel !== 1}
                        defaultValue={detail.Value}
                        InputLabelProps={{
                            shrink: true
                        }}
                        variant={"standard"}
                        margin={"normal"}
                        error={this.isError("Value", "Point", detail)}
                        InputProps={{
                            endAdornment: this.renderInputAdorment(detail.TypeName),
                        }}
                        helperText={this.getErrorMessage("Value", "Point", detail)}
                        onBlur={async (e) => await this.onBlur(e.target.value, "Value", "Point", detail, "")}
                        onValueChange={(e) => this.onChange(e.value || 0, "Value", detail)}
                        style={{marginTop: 2}}
                        inputProps={{min: "0", step: "1"}}
                    />}
                    {typeNum === 1 && this.renderCriterionCombo(detail.EvaluationElementID, "Value", detail, this.UserLevel !== 1)}
                    {typeNum === 2 &&
                    <NumberFormat
                        customInput={TextField}
                        thousandSeparator={false}
                        decimalScale={detail.Decimals || 0}
                        // value={state[dataField] ? String(state[dataField]) : dataOldForm['TempBaseSalary' + no]}
                        fullWidth
                        disabled={true}
                        defaultValue={detail.Value}
                        variant={"standard"}
                        margin={"normal"}
                        InputProps={{
                            endAdornment: this.renderInputAdorment(detail.TypeName),
                        }}
                        style={{marginTop: 2}}
                    />}
                </Grid>
                }
                {level >= 2 &&
                <Grid item xs={12/level} style={pdLevel2}>
                    {typeNum === 0 &&
                    <NumberFormat
                        customInput={TextField}
                        thousandSeparator={false}
                        decimalScale={detail.Decimals || 0}
                        // value={state[dataField] ? String(state[dataField]) : dataOldForm['TempBaseSalary' + no]}
                        fullWidth
                        disabled={this.UserLevel !== 2}
                        defaultValue={detail.Value02}
                        InputLabelProps={{
                            shrink: true
                        }}
                        variant={"standard"}
                        margin={"normal"}
                        error={this.isError("Value02", "Point", detail)}
                        InputProps={{
                            endAdornment: this.renderInputAdorment(detail.Type02Name),
                        }}
                        helperText={this.getErrorMessage("Value02", "Point", detail)}
                        onBlur={async (e) => await this.onBlur(e.target.value, "Value02", "Point", detail, "02")}
                        onValueChange={(e) => this.onChange(e.value || 0, "Value02", detail)}
                        style={{marginTop: 2}}
                        inputProps={{min: "0", step: "1"}}
                    />}
                    {typeNum === 1 && this.renderCriterionCombo(detail.EvaluationElementID, "Value02", detail, this.UserLevel !== 2)}
                    {typeNum === 2 &&
                    <NumberFormat
                        customInput={TextField}
                        thousandSeparator={false}
                        decimalScale={detail.Decimals || 0}
                        // value={state[dataField] ? String(state[dataField]) : dataOldForm['TempBaseSalary' + no]}
                        fullWidth
                        disabled={true}
                        defaultValue={detail.Value02}
                        variant={"standard"}
                        margin={"normal"}
                        InputProps={{
                            endAdornment: this.renderInputAdorment(detail.Type02Name),
                        }}
                        style={{marginTop: 2}}
                    />}
                </Grid>
                }
                {level >= 3 &&
                <Grid item xs={12/level} style={pdLevel3}>
                    {typeNum === 0 &&
                    <NumberFormat
                        customInput={TextField}
                        thousandSeparator={false}
                        decimalScale={detail.Decimals || 0}
                        // value={state[dataField] ? String(state[dataField]) : dataOldForm['TempBaseSalary' + no]}
                        fullWidth
                        disabled={this.UserLevel !== 3}
                        defaultValue={detail.Value03}
                        InputLabelProps={{
                            shrink: true
                        }}
                        variant={"standard"}
                        margin={"normal"}
                        error={this.isError("Value03", "Point", detail)}
                        InputProps={{
                            endAdornment: this.renderInputAdorment(detail.Type03Name),
                        }}
                        helperText={this.getErrorMessage("Value03", "Point", detail)}
                        onBlur={async (e) => await this.onBlur(e.target.value, "Value03", "Point", detail, "03")}
                        onValueChange={(e) => this.onChange(e.value || 0, "Value03", detail)}
                        style={{marginTop: 2}}
                        inputProps={{min: "0", step: "1"}}
                    />}
                    {typeNum === 1 && this.renderCriterionCombo(detail.EvaluationElementID, "Value03", detail, this.UserLevel !== 3)}
                    {typeNum === 2 &&
                    <NumberFormat
                        customInput={TextField}
                        thousandSeparator={false}
                        decimalScale={detail.Decimals || 0}
                        // value={state[dataField] ? String(state[dataField]) : dataOldForm['TempBaseSalary' + no]}
                        fullWidth
                        disabled={true}
                        defaultValue={detail.Value03}
                        variant={"standard"}
                        margin={"normal"}
                        InputProps={{
                            endAdornment: this.renderInputAdorment(detail.Type03Name),
                        }}
                        style={{marginTop: 2}}
                    />}
                </Grid>
                }

                {level >= 1 &&
                <Grid item xs={12 / level} style={pdLevel1} container spacing={0} alignItems={"stretch"}>
                    <Grid item style={{ paddingTop: 6 }}>
                        <Icon name={"Pen"} style={{ color: "#9E9E9E" }} />
                    </Grid>
                    <Grid item style={{ flex: 1, minWidth: 0 }}>
                        <TextInput
                            maxRows={5}
                            multiline={true}
                            style={{ fontStyle: "italic"}}
                            defaultValue={detail.Note1U}
                            disabled={this.UserLevel !== 1}
                            onChange={(value) => this.onChange(value, "Note1U", detail)}
                        />
                    </Grid>
                </Grid>
                }
                {level >= 2 &&
                <Grid item xs={12 / level} style={pdLevel2} container spacing={0} alignItems={"stretch"}>
                    <Grid item style={{ paddingTop: 6 }}>
                        <Icon name={"Pen"} style={{ color: "#9E9E9E" }} />
                    </Grid>
                    <Grid item style={{ flex: 1, minWidth: 0 }}>
                        <TextInput
                            maxRows={5}
                            multiline={true}
                            style={{ fontStyle: "italic" }}
                            defaultValue={detail.Note2U}
                            disabled={this.UserLevel !== 2}
                            onChange={(value) => this.onChange(value, "Note2U", detail)}
                        />
                    </Grid>
                </Grid>
                }
                {level >= 3 &&
                <Grid item xs={12 / level} style={pdLevel3} container spacing={0} alignItems={"stretch"}>
                    <Grid item style={{ paddingTop: 6 }}>
                        <Icon name={"Pen"} style={{ color: "#9E9E9E" }} />
                    </Grid>
                    <Grid item style={{ flex: 1, minWidth: 0 }}>
                        <TextInput
                            maxRows={5}
                            multiline={true}
                            style={{ fontStyle: "italic" }}
                            defaultValue={detail.Note3U}
                            disabled={this.UserLevel !== 3}
                            onChange={(value) => this.onChange(value, "Note3U", detail)}
                        />
                    </Grid>
                </Grid>
                }
            </Grid>
            {data.IsCalculate === 1 &&
            <Icon disabled={this.state.IsSave !== 1} style={{marginLeft: 10, cursor: "pointer"}} width={32} height={32} name={"Math"} color={"primary"}
                  onClick={() => this.getCalResult(detail)}
            />}
            </span>
        );
    };
    renderCriterionResults = (data, level) => {
        const {dataMasterData} = this.props;
        const EvaluationResult = dataMasterData && dataMasterData.EvaluationResult ? dataMasterData.EvaluationResult : 1;
        let pdLevel1 = {padding: "0"};
        let pdLevel2 = {padding: "0"};
        let pdLevel3 = {padding: "0"};

        if (level === 2) {
            pdLevel1 = {padding: "0 10px 0 0"};
            pdLevel2 = {padding: "0 0 0 10px"};
            pdLevel3 = {padding: "0"};
        }
        if (level === 3) {
            pdLevel1 = {padding: "0 10px 0 0"};
            pdLevel2 = {padding: "0 10px 0 10px"};
            pdLevel3 = {padding: "0 0 0 10px"};
        }

        return (
            <Grid
                key={Math.random().toString(36).substring(7)}
                container
                spacing={0}
                direction="row"
                justify="flex-end"
                alignItems="flex-start"
                style={{marginBottom: 20}}>
                <Grid item xs={12} style={{padding: "0 auto"}}>
                    <div>{Config.lang("Ket_qua_danh_gia")} <span style={{color: 'red'}}>*</span></div>
                    {data.Note && <div style={{whiteSpace: 'pre-line', fontStyle: "italic", fontSize: 13}}>{data.Note}</div>}
                </Grid>
                <hr style={{marginTop: 3, marginBottom: 5, width: "100%"}} />

                {level >= 1 &&
                <Grid item xs={12/level} style={pdLevel1}>
                    {this.renderCriterionCboResults( "ResultID1", this.UserLevel !== 1)}
                </Grid>
                }
                {level >= 2 &&
                <Grid item xs={12/level} style={pdLevel2}>
                    {EvaluationResult > 1 && this.renderCriterionCboResults("ResultID2", this.UserLevel !== 2)}
                </Grid>
                }
                {level >= 3 &&
                <Grid item xs={12/level} style={pdLevel3}>
                    {EvaluationResult > 2 && this.renderCriterionCboResults("ResultID3", this.UserLevel !== 3)}
                </Grid>
                }

            </Grid>
        );
    };
    renderCriterionHeader = (EmployeeInfo, CriterionTitle, Note) => {
        return (
            <Row>
                <Col xs={12} sm={4} md={4} lg={4}>
                    {EmployeeInfo && this.renderUserProfile(EmployeeInfo)}
                </Col>
                <Col xs={12} sm={8} md={8} lg={8}>
                    <div style={{textAlign:"center"}}><h2 className={"mgt0 mgb5"}>{CriterionTitle}</h2></div>
                    <div style={{textAlign:"center", fontStyle: "italic"}}><span>{Note}</span></div>
                </Col>
            </Row>
        );
    };
    renderEvaluationLevel = (dataMasterData, level) => {
        let LabelLevel1 = dataMasterData && dataMasterData.LabelLevel1 ? dataMasterData.LabelLevel1 :'';
        let LabelLevel2 = dataMasterData && dataMasterData.LabelLevel2 ? dataMasterData.LabelLevel2 :'';
        let LabelLevel3 = dataMasterData && dataMasterData.LabelLevel3 ? dataMasterData.LabelLevel3 :'';

        if (level === 1) {
            return null;
        }
        return (
            <Grid
                container
                spacing={0}
                direction="row"
                alignItems="center"
                justify="center"
                style={{marginTop: 15}}>

                {level >= 1 &&
                <Grid item align="center" xs={12/level} style={{backgroundColor: "rgb(246, 246, 249)", padding: "10px 0"}}>
                    <b style={{textTransform: "uppercase"}}>{LabelLevel1}</b>
                </Grid>
                }
                {level >= 2 &&
                <Grid item align="center" xs={12/level} style={{backgroundColor: "rgb(240, 240, 243)", padding: "10px 0"}}>
                    <b style={{textTransform: "uppercase"}}>{LabelLevel2}</b>
                </Grid>
                }
                {level >= 3 &&
                <Grid item align="center" xs={12/level} style={{backgroundColor: "rgb(235, 235, 239)", padding: "10px 0"}}>
                    <b style={{textTransform: "uppercase"}}>{LabelLevel3}</b>
                </Grid>
                }
            </Grid>
        );
    };
    renderCriterionFooter = (dataMasterData, level) => {
        const LabelLevel1 = dataMasterData && dataMasterData.LabelLevel1 ? dataMasterData.LabelLevel1 :'';
        const LabelLevel2 = dataMasterData && dataMasterData.LabelLevel2 ? dataMasterData.LabelLevel2 :'';
        const LabelLevel3 = dataMasterData && dataMasterData.LabelLevel3 ? dataMasterData.LabelLevel3 :'';

        const NoteEmployee = this.formData.NoteEmployee ? this.formData.NoteEmployee : dataMasterData.NoteEmployee;
        const NoteEvaluatedLevel2 = this.formData.NoteEvaluatedLevel2 ? this.formData.NoteEvaluatedLevel2 : dataMasterData.NoteEvaluatedLevel2;
        const NoteEvaluatedLevel3 = this.formData.NoteEvaluatedLevel3 ? this.formData.NoteEvaluatedLevel3 : dataMasterData.NoteEvaluatedLevel3;

        return (
            <Grid
                container
                direction="column"
                alignItems="flex-start"
                style={{marginTop: 15}}>

                {level >= 1 &&
                <Grid container direction="row">
                    <Grid item xs={12}>
                        <div><b><i> {Config.lang("Danh_gia_chung")}</i></b></div>
                    </Grid>
                </Grid>
                }
                {level >= 1 &&
                <Grid container direction="row" alignItems={"flex-end"}>
                    {LabelLevel1.length > 0 &&
                    <Grid item xs={3}>
                        <div className={"mgb5"}><b style={{textTransform: "uppercase", marginBottom: 5}}>{LabelLevel1}</b></div>
                    </Grid>
                    }
                    <Grid item xs={LabelLevel1.length > 0 ? 9 : 12}>
                            <TextInput
                                style={{ marginTop: 2, marginBottom: 5 }}
                                maxRows={3}
                                multiline={true}
                                onChange={(e) => this.onChange(e?.target?.value || "", "NoteEmployee")}
                                disabled={this.UserLevel !== 1}
                                defaultValue={NoteEmployee}
                            />
                    </Grid>
                </Grid>
                }
                {level >= 2 &&
                <Grid container direction="row" alignItems={"flex-end"}>
                    <Grid item xs={3}>
                        <div className={"mgb5"}><b style={{textTransform: "uppercase"}}>{LabelLevel2}</b></div>
                    </Grid>
                    <Grid item xs={9}>
                        <TextInput
                                maxRows={3}
                                multiline={true}
                                style={{ marginTop: 2, marginBottom: 5 }}
                                onChange={(e) => this.onChange(e?.target?.value || "", "NoteEvaluatedLevel2")}
                                disabled={this.UserLevel !== 2}
                                defaultValue={NoteEvaluatedLevel2}
                            />
                    </Grid>
                </Grid>
                }
                {level >= 3 &&
                <Grid container direction="row" alignItems={"flex-end"}>
                    <Grid item xs={3}>
                        <div className={"mgb5"}><b style={{textTransform: "uppercase"}}>{LabelLevel3}</b></div>
                    </Grid>
                    <Grid item xs={9}>
                        <TextInput
                                maxRows={3}
                                multiline={true}
                                style={{ marginTop: 2, marginBottom: 5 }}
                                onChange={(e) => this.onChange(e?.target?.value || "", "NoteEvaluatedLevel3")}
                                disabled={this.UserLevel !== 3}
                                defaultValue={NoteEvaluatedLevel3}
                            />
                    </Grid>
                </Grid>
                }
            </Grid>
        );
    };
    renderHistoryApproval = (dataHistoryApproval) => {
        const {classes} = this.props;

        return (
            <Grid
                container
                direction="column"
                alignItems="flex-start"
                style={{marginTop: 15}}>

                <Grid container direction="row" className={"mgb15"}>
                    <Grid item xs={12}>
                        <div><b><i>* {Config.lang("Lich_su_duyet")}</i></b></div>
                    </Grid>
                </Grid>

                {dataHistoryApproval && dataHistoryApproval.map((data, idx) => {
                    let date = _.get(data, "ApprovalDate", "");
                    if (date && moment(date).isValid()) {
                        date = moment(date).format("DD/MM/YYYY kk:mm:ss");
                    }
                    const user = Config.getUser({UserID: data.ApproverID || ""});
                    return (
                        <Grid key={idx} container direction="row" alignItems={"flex-end"}
                              className={"mgb15"}>
                            <Grid item xs={3}>
                                <div className={"display_row align_center"}>
                                    <UserImage data={data} valueExpr={"UserID"} keyExpr={"ApproverID"} allowHover={false}/>
                                    <div>
                                        <div style={{ fontSize: '1.12rem' }}>
                                            <UserName data={data}
                                                      valueExpr={"UserID"}
                                                      keyExpr={"ApproverID"}
                                                      allowHover={false}
                                                      displayExpr={"UserName"}/> - {data.ApprovalStatusName || ""}
                                        </div>
                                        <div className={classes.dateInfo}>
                                            <Image src={require("../../../../assets/images/icon-calendar.svg")} />
                                            <div style={{ paddingLeft: "8px" }} className={"date-text-info"}>
                                                {date}
                                            </div>
                                        </div>
                                        {user && user.DutyName && <div>{user.DutyName || ""}</div>}
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={9}>
                            <TextInput
                                    maxRows={3}
                                    multiline={true}
                                    disabled={true}
                                    style={{ marginTop: 2 }}
                                    defaultValue={data.ApprovalNotes || ""}
                                />
                            </Grid>
                        </Grid>
                    );
                })}
            </Grid>
        );
    };
    printDocument = async () => {
        if (this.canvasHtml) {
            try {
                this.setState({loading2: true}, async () => {
                    setTimeout(async () => { //await for show loading
                        if (!this.canvasImage) {
                            const print     = document.createElement("div");
                            print.innerHTML = this.canvasHtml.outerHTML;
                            document.body.appendChild(print);
                            const canvas  = await html2canvas(print, {
                                scale:      '1',
                                allowTaint: true,
                                useCORS:    true,
                                scrollX:    0,
                                scrollY:    -window.scrollY
                            });
                            this.canvasImage = canvas.toDataURL('image/png');
                            print.remove(); //remove element div print
                        }
                        const myWindow = await window.open('', Config.lang("Danh_gia_nhan_vien"));
                        this.setState({loading2: false});
                        if (!myWindow) {
                            Config.popup2.show("INFO", Config.lang("Khong_the_mo_tab_moi"));
                            return false;
                        } else {
                            myWindow.document.write('<body onload="window.print();window.close();"><img src="' + this.canvasImage + '" /></body>');
                            myWindow.document.close();
                            myWindow.focus();
                        }
                    }, 300);
                });
            } catch (e) {
                this.setState({loading2: false});
                Config.popup2.show("INFO", Config.lang("Co_loi_xay_ra_trong_qua_trinh_xu_ly"));
                return false;
            }
        } else {
            Config.popup2.show("INFO", Config.lang("Dang_chuan_bi_du_lieu_Xin_vui_long_cho"));
            return false;
        }
    };

    getCalResult = (detail) => {
        this.setState({loading2: true});
        const { Mode, EmployeeID, ApproveLevel, EvaluationVoucherID } = this.formData;
        const { EvaluationElementID = "" } = detail;
        const TransTypeID = this.TransTypeID || "";
        const dataDetail = [...this.dataDetail];
        let dataValue = [];
        if(!_.isEmpty(dataDetail)){
            dataDetail.forEach(item => {
                dataValue.push({
                    EvaluationElementID: item.EvaluationElementID,
                    Value: item.Value,
                    Value02: item.Value02,
                    Value03: item.Value03,
                })
            })
        }
        const param = {
            Mode,
            FormID: "W39F1011",
            EmployeeID,
            TransTypeID,
            ApproveLevel: ApproveLevel.toString(),
            EvaluationVoucherID,
            EvaluationElementID,
            data: JSON.stringify(dataValue)
        };

        this.props.W39F1011Actions.getResult(param, (error, data) => {
            this.setState({loading2: false});
            if (error) {
                Config.popup.show("ERROR", error || Config.lang("Loi_chua_xac_dinh"));
                return false;
            } else if (data) {
                 if(!Config.isEmpty(data.Value, false)) this.onChange(data.Value, "Value", detail, true);
            }
        });
    }

    render() {
        const {
            dataOldAttachments,
            attachmentLoading,
            loading,
            loading2,
            uploading,

            CriterionTitle,
            Note,
            EmployeeInfo,
            errorMessage,
            IsSave,
        } = this.state;
        const { classes, dataMasterData, dataHistoryApproval } = this.props;
        const criterion = this.parseDataGroupCriterion();

        let level = 1;
        if (dataMasterData && dataMasterData.NumEvaluationLevel) {
            level = parseInt(dataMasterData.NumEvaluationLevel);
            level = level > 3 || level < 1 ? 1 : level;
        }

        let count = 0;
        return (
            <>
                <ActionToolbar alignment="flex-end" title={Config.lang("Danh_gia_nhan_vien")} onBack={this.onBack}>
                    {!loading && !errorMessage && <>
                <Button
                    size={"medium"}
                    color={"primary"}
                    startIcon={"Pdf"}
                    className={"mgr10"}
                    viewType={"outlined"}
                    label={Config.lang("Xuat_PDF")}
                    style={{ textTransform: "uppercase" }}
                    onClick={this.printDocument}
                />
                <Button
                    ref={ref => this.btnSaveRef["save"] = ref}
                    disabled={!this.isChangeForm || (IsSave !== 1)}
                    size={"medium"}
                    color={"info"}
                    startIcon={"SaveV2"}
                    viewType={"filled"}
                    label={Config.lang("Luu")}
                    style={{ textTransform: "uppercase" }}
                    onClick={this.onSave}
                />
                    </>}
                </ActionToolbar>

                <LoadPanel
                    position={{my: 'center', of: null}}
                    visible={loading || loading2}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                />
                {!loading && errorMessage &&
                <Container maxWidth="lg" style={{ paddingTop: 20, marginLeft: 0 }}>
                    <center>{errorMessage}</center>
                </Container>
                }
                {!loading && !errorMessage && <>
                <Container innerRef={this.contentContainer} maxWidth="lg" style={{paddingTop: 20, marginLeft: 0, width: "100%"}}>
                    {EmployeeInfo && CriterionTitle && this.renderCriterionHeader(EmployeeInfo, CriterionTitle, Note)}

                    {dataMasterData && this.renderEvaluationLevel(dataMasterData, level)}

                    {criterion && criterion.map((o, i) => {
                        const ListCriterionDetail = o.ListCriterionDetail && o.ListCriterionDetail.filter(c => c.IsMiddleEvaluationElement === 0);
                        if (ListCriterionDetail.length <= 0) return null;
                        const sectionNum = convertToRoman(count + 1);
                        count++;
                        return (
                            <FormControl key={i} component={"fieldset"} className={classes.fieldset}>
                                <Row>
                                    <Col xs={12}>
                                        {sectionNum}. {o.AppCriterionGroupNameU} &nbsp;
                                        {o.AppCriterionGroupDescU &&
                                        <Tooltip title={o.AppCriterionGroupDescU} placement="right">
                                            <sup>
                                                <i className="fa fa-info-circle" aria-hidden="true" style={{color: "#9E9E9E"}}></i>
                                            </sup>
                                        </Tooltip>}
                                    </Col>
                                </Row>

                                {o.ListCriterionDetail && o.ListCriterionDetail.map((c) => {
                                    return c.IsMiddleEvaluationElement === 0 ? this.renderCriterionDetail(c, level) : null;
                                })}
                            </FormControl>);
                    })}

                    {dataMasterData && dataMasterData.IsEvaluateContract === 1 && <FormControl component={"fieldset"} className={classes.fieldset}>
                        <Row>
                            <Col xs={12}>
                                {this.renderCriterionResults(dataMasterData, level)}
                            </Col>
                        </Row>
                    </FormControl>}

                    {dataMasterData && this.renderCriterionFooter(dataMasterData, level)}

                    {dataHistoryApproval && dataHistoryApproval.length > 0 && this.renderHistoryApproval(dataHistoryApproval, level)}
                </Container>
                {dataMasterData &&
                <FormGroup>
                    <Row style={{marginTop: 30, width: '100%'}}>
                        <Col xs={12}>
                            <Attachments
                                isAttachInfo
                                ref={(ref) => (this.attRef = ref)}
                                showButton={true}
                                files={dataOldAttachments}
                                disabled={uploading}
                                maxLength={5}
                                uploading={attachmentLoading}
                                onUploading={this.onUploading}
                                onChanged={this.onChangeAttachments}
                            />
                        </Col>
                    </Row>
                </FormGroup>}
                {dataMasterData &&
                <FormGroup style={{display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "flex-end"}}>
                <Button
                    size={"medium"}
                    color={"primary"}
                    startIcon={"Pdf"}
                    className={"mgr10"}
                    viewType={"outlined"}
                    label={Config.lang("Xuat_PDF")}
                    style={{ textTransform: "uppercase" }}
                    onClick={this.printDocument}
                />
                <Button
                    ref={ref => this.btnSaveRef["save1"] = ref}
                    disabled={!this.isChangeForm || (IsSave !== 1)}
                    size={"medium"}
                    color={"info"}
                    startIcon={"SaveV2"}
                    viewType={"filled"}
                    label={Config.lang("Luu")}
                    style={{ textTransform: "uppercase" }}
                    onClick={this.onSave}
                />
                <div id={"print"} className={"hidden"} />
                </FormGroup>
                    }
                </>}
            </>
        );
    }
}
export default compose(
    connect(
        state => ({
            dataMasterData: state.W39F1011.dataMasterData,
            dataCriterionGroup: state.W39F1011.dataCriterionGroup,
            dataCriterionCombo: state.W39F1011.dataCriterionCombo,
            dataCriterionDetail: state.W39F1011.dataCriterionDetail,
            dataComboResults: state.W39F1011.dataComboResults,
            dataSaveResult: state.W39F1011.dataSaveResult,
            dataHistoryApproval: state.W39F1011.dataHistoryApproval,
            getRequiredFields: state.general.getRequiredFields,
        }),
        dispatch => ({
            mainActions: bindActionCreators(mainActions, dispatch),
            generalActions: bindActionCreators(generalActions, dispatch),
            W39F1011Actions: bindActionCreators(W39F1011Actions, dispatch)
        })
    ),
    withStyles(styles, { withTheme: true })
)(W39F1011);
